import React from 'react';
import { Box, Text, Image, VStack, HStack, Avatar, useColorModeValue, SimpleGrid } from '@chakra-ui/react';
import { Message } from '../types/chat';
import TrafficMonitoringCard from './TrafficMonitoringCard';
import SafetyMonitoringCard from './SafetyMonitoringCard';
import ATMMonitoringCard from './ATMMonitoringCard';
import RetailMonitoringCard from './RetailMonitoringCard';

interface ChatMessageProps {
  message: Message;
}

const ChatMessage: React.FC<ChatMessageProps> = ({ message }) => {
  const isUser = message.role === 'user';
  const avatarBgUser = useColorModeValue('green.500', 'green.400');
  const avatarBgAssistant = useColorModeValue('blue.500', 'blue.400');
  const userBg = useColorModeValue('blue.500', 'blue.600');
  const userColor = useColorModeValue('white', 'white');
  const assistantBg = useColorModeValue('gray.100', 'gray.700');
  const assistantColor = useColorModeValue('black', 'white');
  const codeBg = useColorModeValue('gray.50', 'gray.800');
  const codeColor = useColorModeValue('black', 'white');

  const hasJsonData = message.jsonData !== undefined;
  const analysis = message.content;
  let jsonData = '';

  if (hasJsonData) {
    try {
      const parsedJson = typeof message.jsonData === 'string' ? JSON.parse(message.jsonData) : message.jsonData;
      jsonData = JSON.stringify(parsedJson, null, 2);
      
      // Check if the JSON data matches the traffic monitoring format
      const isTrafficData = 'people_count' in parsedJson && 'vehicle_count' in parsedJson && 'is_congested' in parsedJson;
      
      // Check if the JSON data matches the industry safety format
      const isSafetyData = 'worker_count' in parsedJson && 'ppe_compliance_count' in parsedJson;

      // Check if the JSON data matches the ATM monitoring format
      const isATMData = 'real_person_count' in parsedJson && 'face_count' in parsedJson && 'atm_count' in parsedJson;

      // Check if the JSON data matches the retail monitoring format
      const isRetailData = 'customer_count' in parsedJson && 'staff_count' in parsedJson && 'shelf_occupancy_percentage' in parsedJson;
      
      if (isTrafficData || isSafetyData || isATMData || isRetailData) {
        return (
          <HStack
            alignItems="flex-start"
            justifyContent={isUser ? 'flex-end' : 'flex-start'}
            spacing={2}
            w="full"
          >
            {!isUser && (
              <Avatar
                size="sm"
                name="AI Assistant"
                bg={avatarBgAssistant}
                color="white"
              />
            )}
            <Box maxW="90%" w="full">
              {analysis && (
                <Text
                  mb={4}
                  color={isUser ? userColor : assistantColor}
                >
                  {analysis}
                </Text>
              )}
              {isTrafficData ? (
                <TrafficMonitoringCard data={parsedJson} />
              ) : isSafetyData ? (
                <SafetyMonitoringCard data={parsedJson} />
              ) : isATMData ? (
                <ATMMonitoringCard data={parsedJson} />
              ) : (
                <RetailMonitoringCard data={parsedJson} />
              )}
            </Box>
          </HStack>
        );
      }
    } catch (error) {
      console.log(message.content);
      console.error('Failed to parse JSON');
      console.log(message.jsonData);
    }
  }

  return (
    <HStack
      alignItems="flex-start"
      justifyContent={isUser ? 'flex-end' : 'flex-start'}
      spacing={2}
      w="full"
    >
      {!isUser && (
        <Avatar
          size="sm"
          name="AI Assistant"
          bg={avatarBgAssistant}
          color="white"
        />
      )}
      <VStack align={isUser ? 'flex-end' : 'flex-start'} spacing={4} maxW="90%">
        {/* User message with image */}
        {message.content && (
          <Box
            p={4}
            borderRadius="lg"
            bg={isUser ? userBg : assistantBg}
            color={isUser ? userColor : assistantColor}
            width="100%"
          >
            <Text whiteSpace="pre-wrap">{message.content}</Text>
          </Box>
        )}
        {message.image && (
          <Image
            src={message.image}
            alt="Uploaded image"
            maxH="300px"
            borderRadius="lg"
          />
        )}

        {/* Safety Analysis Results */}
        {!isUser && message.jsonData && (
          <Box p={4} borderWidth="1px" borderRadius="lg" width="100%">
            <Text fontWeight="bold" mb={3}>Safety Analysis Results:</Text>
            <SimpleGrid columns={2} spacing={4}>
              <Box>
                <Text fontWeight="semibold">Worker Safety:</Text>
                <Text>Workers Present: {message.jsonData.worker_count}</Text>
                <Text>PPE Compliance: {message.jsonData.ppe_compliance_count}</Text>
                <Text>Helmets: {message.jsonData.helmet_count}</Text>
                <Text>Safety Vests: {message.jsonData.safety_vest_count}</Text>
                <Text>Safety Goggles: {message.jsonData.safety_goggles_count}</Text>
                <Text>Safety Gloves: {message.jsonData.safety_gloves_count}</Text>
              </Box>
              <Box>
                <Text fontWeight="semibold">Hazard Detection:</Text>
                <Text>Unsafe Acts: {message.jsonData.unsafe_acts_count}</Text>
                <Text>Hazardous Materials: {message.jsonData.hazardous_materials_present ? "Yes" : "No"}</Text>
                <Text>Equipment Running: {message.jsonData.equipment_running_count}</Text>
                <Text>Exits Blocked: {message.jsonData.emergency_exits_blocked ? "Yes" : "No"}</Text>
                <Text>Trip Hazards: {message.jsonData.trip_hazards_present ? "Yes" : "No"}</Text>
                <Text>Lifting Hazards: {message.jsonData.lifting_hazards_detected ? "Yes" : "No"}</Text>
              </Box>
            </SimpleGrid>
          </Box>
        )}

        {/* Object Detection Results */}
        {!isUser && message.od_image && (
          <Box p={4} borderWidth="1px" borderRadius="lg" width="100%">
            <Text fontWeight="bold" mb={3}>Object Detection Results:</Text>
            <Box position="relative" width="100%">
              <Image
                src={`data:image/jpeg;base64,${message.od_image}`}
                alt="Object detection results"
                maxH="500px"
                width="100%"
                objectFit="contain"
                borderRadius="lg"
              />
            </Box>
            {message.od_response && (
              <Box mt={4}>
                <Text fontWeight="semibold" mb={2}>Detected Objects:</Text>
                <Box p={3} bg="gray.50" borderRadius="md">
                  <Text fontSize="sm">
                    {message.od_response.split('<loc_').map((item: string) => {
                      const label = item.split('>')[1];
                      return label && !label.includes('s') ? (
                        <Text as="span" key={item} mr={2} color="blue.600">
                          {label}
                        </Text>
                      ) : null;
                    })}
                  </Text>
                </Box>
              </Box>
            )}
          </Box>
        )}
      </VStack>
      {isUser && (
        <Avatar
          size="sm"
          name="User"
          bg={avatarBgUser}
          color="white"
        />
      )}
    </HStack>
  );
};

export default ChatMessage;
