import React from 'react';
import {
  Box,
  Grid,
  Text,
  VStack,
  HStack,
  Badge,
  useColorModeValue,
  Icon,
  Progress,
} from '@chakra-ui/react';
import { 
  FaUsers,
  FaUserTie,
  FaChild,
  FaShoppingCart,
  FaShoppingBasket,
  FaStore,
  FaCashRegister,
  FaBoxes,
  FaExclamationTriangle,
  FaUserShield,
  FaDoorOpen,
  FaMask,
  FaHandsHelping,
  FaBoxOpen,
  FaUserFriends,
  FaWater,
} from 'react-icons/fa';

type RetailData = {
  [key: string]: number | boolean | string;
};

interface RetailMonitoringCardProps {
  data: RetailData;
}

const RetailMonitoringCard: React.FC<RetailMonitoringCardProps> = ({ data }) => {
  const cardBg = useColorModeValue('white', 'gray.800');
  const borderColor = useColorModeValue('gray.200', 'gray.600');
  const progressTrackColor = useColorModeValue('gray.100', 'gray.600');
  const progressFilledColor = useColorModeValue('green.500', 'green.300');
  const isDark = useColorModeValue(false, true);

  // Helper function to format field names for display
  const formatFieldName = (field: string): string => {
    return field
      .replace(/_/g, ' ')
      .replace(/is /g, '')
      .replace(/count/g, '')
      .split(' ')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ')
      .trim();
  };

  // Separate different types of values
  const numericFields: { [key: string]: number } = {};
  const booleanFields: { [key: string]: boolean } = {};
  const percentageFields: { [key: string]: number } = {};

  Object.entries(data).forEach(([key, value]) => {
    if (typeof value === 'number') {
      if (key.includes('percentage')) {
        percentageFields[key] = value;
      } else {
        numericFields[key] = value;
      }
    } else if (typeof value === 'boolean') {
      booleanFields[key] = value;
    }
  });

  const getIconForField = (key: string): React.ElementType => {
    if (key.includes('customer')) return FaUsers;
    if (key.includes('staff')) return FaUserTie;
    if (key.includes('children')) return FaChild;
    if (key.includes('cart')) return FaShoppingCart;
    if (key.includes('basket')) return FaShoppingBasket;
    if (key.includes('store')) return FaStore;
    if (key.includes('checkout')) return FaCashRegister;
    if (key.includes('shelf') || key.includes('inventory')) return FaBoxes;
    if (key.includes('hazard')) return FaExclamationTriangle;
    if (key.includes('security')) return FaUserShield;
    if (key.includes('emergency') || key.includes('exit')) return FaDoorOpen;
    if (key.includes('mask')) return FaMask;
    if (key.includes('assistance')) return FaHandsHelping;
    if (key.includes('display')) return FaBoxOpen;
    if (key.includes('social') || key.includes('distancing')) return FaUserFriends;
    if (key.includes('spill')) return FaWater;
    return FaStore;
  };

  const getSentimentColor = (key: string, value: number | boolean): { bg: string; text: string } => {
    // Handle boolean values
    if (typeof value === 'boolean') {
      if (key.includes('theft') || key.includes('suspicious') || key.includes('emergency')) {
        return value ? 
          { bg: isDark ? 'red.900' : 'red.50', text: isDark ? 'red.200' : 'red.600' } :
          { bg: isDark ? 'green.900' : 'green.50', text: isDark ? 'green.200' : 'green.600' };
      }
      return value ?
        { bg: isDark ? 'blue.900' : 'blue.50', text: isDark ? 'blue.200' : 'blue.600' } :
        { bg: isDark ? 'gray.700' : 'gray.50', text: isDark ? 'gray.200' : 'gray.600' };
    }

    // Handle numeric values
    if (key.includes('customer')) {
      if (value > 50) return { bg: isDark ? 'red.900' : 'red.50', text: isDark ? 'red.200' : 'red.600' };
      if (value > 30) return { bg: isDark ? 'yellow.900' : 'yellow.50', text: isDark ? 'yellow.200' : 'yellow.600' };
      return { bg: isDark ? 'green.900' : 'green.50', text: isDark ? 'green.200' : 'green.600' };
    }

    if (key.includes('staff')) {
      if (value < 2) return { bg: isDark ? 'red.900' : 'red.50', text: isDark ? 'red.200' : 'red.600' };
      if (value < 4) return { bg: isDark ? 'yellow.900' : 'yellow.50', text: isDark ? 'yellow.200' : 'yellow.600' };
      return { bg: isDark ? 'green.900' : 'green.50', text: isDark ? 'green.200' : 'green.600' };
    }

    if (key.includes('shelf_occupancy') || key.includes('stock')) {
      const percentage = key.includes('percentage') ? value : (value * 100);
      if (percentage < 30) return { bg: isDark ? 'red.900' : 'red.50', text: isDark ? 'red.200' : 'red.600' };
      if (percentage < 70) return { bg: isDark ? 'yellow.900' : 'yellow.50', text: isDark ? 'yellow.200' : 'yellow.600' };
      return { bg: isDark ? 'green.900' : 'green.50', text: isDark ? 'green.200' : 'green.600' };
    }

    if (key.includes('queue') || key.includes('waiting')) {
      if (value > 10) return { bg: isDark ? 'red.900' : 'red.50', text: isDark ? 'red.200' : 'red.600' };
      if (value > 5) return { bg: isDark ? 'yellow.900' : 'yellow.50', text: isDark ? 'yellow.200' : 'yellow.600' };
      return { bg: isDark ? 'green.900' : 'green.50', text: isDark ? 'green.200' : 'green.600' };
    }

    // Default color scheme
    return { bg: isDark ? 'blue.900' : 'blue.50', text: isDark ? 'blue.200' : 'blue.600' };
  };

  const StatBox = ({ 
    label, 
    value, 
    icon,
    isAlert = false,
  }: { 
    label: string; 
    value: number | boolean; 
    icon: React.ElementType;
    isAlert?: boolean;
  }) => {
    const colors = getSentimentColor(label.toLowerCase(), value);

    return (
      <Box
        p={4}
        borderRadius="lg"
        bg={colors.bg}
        position="relative"
        overflow="hidden"
      >
        <VStack spacing={1} align="start">
          <HStack spacing={2}>
            <Icon as={icon} color={colors.text} boxSize={5} />
            <Text fontSize="sm" fontWeight="medium" color={colors.text}>
              {label}
            </Text>
          </HStack>
          <Text fontSize="2xl" fontWeight="bold" color={colors.text}>
            {typeof value === 'boolean' ? (value ? 'Yes' : 'No') : value}
          </Text>
        </VStack>
      </Box>
    );
  };

  const AlertBadge = ({ isActive, label }: { isActive: boolean; label: string }) => {
    const colors = getSentimentColor(label.toLowerCase(), isActive);
    return (
      <Badge
        bg={colors.bg}
        color={colors.text}
        px={2}
        py={1}
        borderRadius="full"
      >
        {label}: {isActive ? 'Yes' : 'No'}
      </Badge>
    );
  };

  // Group numeric fields by category
  const categorizeNumeric = (key: string): string => {
    if (key.includes('customer') || key.includes('staff') || key.includes('children')) {
      return 'People Count';
    } else if (key.includes('checkout') || key.includes('queue')) {
      return 'Checkout Status';
    } else if (key.includes('cart') || key.includes('basket') || key.includes('items')) {
      return 'Shopping Items';
    } else {
      return 'Other Metrics';
    }
  };

  // Group boolean fields by category
  const categorizeBooleans = (key: string): { category: string; isPositive: boolean } => {
    if (key.includes('emergency') || key.includes('hazard') || key.includes('suspicious')) {
      return { category: 'Safety & Security', isPositive: false };
    } else if (key.includes('checkout') || key.includes('assistance')) {
      return { category: 'Store Operations', isPositive: true };
    } else if (key.includes('social') || key.includes('mask')) {
      return { category: 'Health Compliance', isPositive: true };
    } else {
      return { category: 'Other Status', isPositive: false };
    }
  };

  // Group numeric fields
  const groupedNumericFields: { [category: string]: { [key: string]: number } } = {};
  Object.entries(numericFields).forEach(([key, value]) => {
    const category = categorizeNumeric(key);
    if (!groupedNumericFields[category]) {
      groupedNumericFields[category] = {};
    }
    groupedNumericFields[category][key] = value;
  });

  // Group boolean fields
  const groupedBooleanFields: { [category: string]: { key: string; value: boolean; isPositive: boolean }[] } = {};
  Object.entries(booleanFields).forEach(([key, value]) => {
    const { category, isPositive } = categorizeBooleans(key);
    if (!groupedBooleanFields[category]) {
      groupedBooleanFields[category] = [];
    }
    groupedBooleanFields[category].push({ key, value, isPositive });
  });

  return (
    <Box
      bg={cardBg}
      p={6}
      borderRadius="xl"
      boxShadow="xl"
      border="1px"
      borderColor={borderColor}
    >
      <VStack spacing={6} align="stretch">
        {/* Percentage Metrics */}
        {Object.entries(percentageFields).map(([key, value]) => (
          <Box key={key}>
            <HStack justify="space-between" mb={2}>
              <Text fontSize="sm" fontWeight="medium">
                {formatFieldName(key)}
              </Text>
              <Text fontSize="sm" fontWeight="bold">
                {value}%
              </Text>
            </HStack>
            <Progress
              value={value}
              colorScheme={value > 80 ? 'green' : value > 50 ? 'yellow' : 'red'}
              borderRadius="full"
              size="sm"
              bg={progressTrackColor}
            />
          </Box>
        ))}

        {/* Numeric Metrics */}
        {Object.entries(groupedNumericFields).map(([category, fields]) => (
          <Box key={category}>
            <Text fontSize="lg" fontWeight="bold" mb={3}>
              {category}
            </Text>
            <Grid templateColumns={['repeat(2, 1fr)', 'repeat(3, 1fr)', 'repeat(4, 1fr)']} gap={4}>
              {Object.entries(fields).map(([key, value]) => (
                <StatBox
                  key={key}
                  label={formatFieldName(key)}
                  value={value}
                  icon={getIconForField(key)}
                  isAlert={false}
                />
              ))}
            </Grid>
          </Box>
        ))}

        {/* Boolean Status Indicators */}
        {Object.entries(groupedBooleanFields).map(([category, fields]) => (
          <Box key={category}>
            <Text fontSize="lg" fontWeight="bold" mb={3}>
              {category}
            </Text>
            <Grid templateColumns={['repeat(2, 1fr)', 'repeat(3, 1fr)']} gap={3}>
              {fields.map(({ key, value, isPositive }) => (
                <AlertBadge
                  key={key}
                  isActive={value}
                  label={formatFieldName(key)}
                />
              ))}
            </Grid>
          </Box>
        ))}
      </VStack>
    </Box>
  );
};

export default RetailMonitoringCard;
