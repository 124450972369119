import { useState, useEffect } from 'react';
import { ChatState, Message } from '../types/chat';
import { databaseService } from '../services/DatabaseService';

export const useChatState = (botType: string) => {
  const [chatState, setChatState] = useState<ChatState>({
    messages: [],
    isLoading: false,
    error: null,
  });

  // Load messages from database on component mount
  useEffect(() => {
    const loadMessages = async () => {
      try {
        const messages = await databaseService.getChat(botType);
        setChatState(prev => ({ ...prev, messages }));
      } catch (error) {
        console.error('Error loading messages:', error);
      }
    };
    loadMessages();
  }, [botType]);

  // Save messages to database whenever they change
  useEffect(() => {
    const saveMessages = async () => {
      try {
        await databaseService.saveChat(botType, chatState.messages);
      } catch (error) {
        console.error('Error saving messages:', error);
      }
    };
    if (chatState.messages.length > 0) {
      saveMessages();
    }
  }, [botType, chatState.messages]);

  const clearChat = async () => {
    try {
      await databaseService.clearChat(botType);
      setChatState({ messages: [], isLoading: false, error: null });
    } catch (error) {
      console.error('Error clearing chat:', error);
    }
  };

  return {
    chatState,
    setChatState,
    clearChat,
  };
};
