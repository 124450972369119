import React from 'react';
import {
  Box,
  Input,
  Button,
  HStack,
  IconButton,
  useToast,
  useColorModeValue,
  Text,
  Tag,
  TagLabel,
  VStack,
} from '@chakra-ui/react';
import { AttachmentIcon, DeleteIcon, SettingsIcon } from '@chakra-ui/icons';

interface ChatInputProps {
  onSendMessage: (text: string, image?: File) => Promise<void>;
  isLoading: boolean;
  onRulesClick?: () => void;
  onSamplesClick?: () => void;
  selectedRules?: string[];
  selectedSampleImage?: string | null;
}

const ChatInput: React.FC<ChatInputProps> = ({
  onSendMessage,
  isLoading,
  onRulesClick,
  onSamplesClick,
  selectedRules = [],
  selectedSampleImage = null,
}) => {
  const [message, setMessage] = React.useState('');
  const [selectedImage, setSelectedImage] = React.useState<File | null>(null);
  const fileInputRef = React.useRef<HTMLInputElement>(null);
  const toast = useToast();

  const inputBg = useColorModeValue('white', 'gray.700');
  const inputColor = useColorModeValue('gray.800', 'white');
  const borderColor = useColorModeValue('gray.200', 'gray.600');
  const buttonBg = useColorModeValue('blue.500', 'blue.400');
  const buttonHoverBg = useColorModeValue('blue.600', 'blue.500');
  const iconColor = useColorModeValue('gray.500', 'gray.400');
  const tagBg = useColorModeValue('blue.50', 'blue.900');
  const tagColor = useColorModeValue('blue.800', 'blue.100');

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!message.trim() && !selectedImage && !selectedSampleImage) return;

    try {
      if (selectedSampleImage) {
        // Convert sample image URL to File object
        const response = await fetch(selectedSampleImage);
        const blob = await response.blob();
        const file = new File([blob], 'sample-image.jpg', { type: 'image/jpeg' });
        await onSendMessage(message, file);
      } else {
        await onSendMessage(message, selectedImage || undefined);
      }
      setMessage('');
      setSelectedImage(null);
    } catch (error) {
      toast({
        title: 'Error',
        description: 'Failed to send message',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const handleImageSelect = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files && e.target.files[0];
    if (file) {
      if (file.type.startsWith('image/')) {
        setSelectedImage(file);
      } else {
        toast({
          title: 'Invalid file type',
          description: 'Please select an image file',
          status: 'warning',
          duration: 3000,
          isClosable: true,
        });
      }
    }
  };

  return (
    <Box as="form" onSubmit={handleSubmit}>
      {selectedRules.length > 0 && (
        <Box mb={2}>
          <Text fontSize="sm" color={inputColor} mb={1}>
            Active Rules:
          </Text>
          <HStack spacing={2} flexWrap="wrap">
            {selectedRules.map((rule, index) => (
              <Tag
                key={index}
                size="sm"
                borderRadius="full"
                variant="solid"
                bg={tagBg}
                color={tagColor}
              >
                <TagLabel>{rule}</TagLabel>
              </Tag>
            ))}
          </HStack>
        </Box>
      )}

      <VStack spacing={2}>
        <HStack w="full" spacing={2}>
          <Input
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            placeholder="Type your message..."
            bg={inputBg}
            color={inputColor}
            borderColor={borderColor}
            _placeholder={{ color: 'gray.500' }}
            _hover={{ borderColor: borderColor }}
            _focus={{ borderColor: buttonBg, boxShadow: 'none' }}
          />

          <IconButton
            icon={<AttachmentIcon />}
            aria-label="Attach image"
            onClick={() => fileInputRef.current?.click()}
            isDisabled={isLoading || !!selectedSampleImage}
            colorScheme="gray"
            variant="ghost"
            color={iconColor}
            _hover={{ bg: tagBg }}
            size="md"
          />

          {onSamplesClick && (
            <IconButton
              icon={<AttachmentIcon />}
              aria-label="Choose sample image"
              onClick={onSamplesClick}
              isDisabled={isLoading || !!selectedImage}
              colorScheme="gray"
              variant="ghost"
              color={iconColor}
              _hover={{ bg: tagBg }}
            />
          )}

          {onRulesClick && (
            <IconButton
              icon={<SettingsIcon />}
              aria-label="Configure rules"
              onClick={onRulesClick}
              colorScheme="gray"
              variant="ghost"
              color={iconColor}
              _hover={{ bg: tagBg }}
            />
          )}

          <Button
            type="submit"
            colorScheme="blue"
            bg={buttonBg}
            _hover={{ bg: buttonHoverBg }}
            isLoading={isLoading}
            loadingText="Sending..."
          >
            Send
          </Button>
        </HStack>

        {selectedImage && (
          <HStack w="full" spacing={2} bg={tagBg} p={2} borderRadius="md">
            <Text fontSize="sm" color={tagColor}>
              Selected image: {selectedImage.name}
            </Text>
            <IconButton
              icon={<DeleteIcon />}
              aria-label="Remove image"
              size="sm"
              colorScheme="red"
              variant="ghost"
              onClick={() => setSelectedImage(null)}
            />
          </HStack>
        )}

        {selectedSampleImage && (
          <HStack w="full" spacing={2} bg={tagBg} p={2} borderRadius="md">
            <Text fontSize="sm" color={tagColor}>
              Selected sample image
            </Text>
            <IconButton
              icon={<DeleteIcon />}
              aria-label="Remove sample image"
              size="sm"
              colorScheme="red"
              variant="ghost"
              onClick={() => onSamplesClick && onSamplesClick()}
            />
          </HStack>
        )}
      </VStack>

      <input
        type="file"
        ref={fileInputRef}
        onChange={handleImageSelect}
        accept="image/*"
        style={{ display: 'none' }}
      />
    </Box>
  );
};

export default ChatInput;
