import React from 'react';
import { Box, VStack, Icon, Flex, IconButton, useColorModeValue, Tooltip } from '@chakra-ui/react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import { 
  FiHome, 
  FiMonitor, 
  FiAlertCircle, 
  FiSettings, 
  FiLink, 
  FiBell,
  FiShield,
  FiUsers,
  FiUserCheck,
  FiMapPin,
  FiXOctagon,
  FiShoppingBag,
  FiActivity,
  FiHardDrive,
  FiCamera,
  FiChevronLeft,
  FiChevronRight,
  FiVideo,
  FiCrosshair
} from 'react-icons/fi';
import { useSidebar } from '../contexts/SidebarContext';

interface NavItemProps {
  icon: any;
  children: string;
  to: string;
  isCollapsed: boolean;
}

const NavItem = ({ icon, children, to, isCollapsed }: NavItemProps) => {
  const location = useLocation();
  const isActive = location.pathname === to;
  const hoverBg = useColorModeValue('cyan.400', 'cyan.600');
  const hoverColor = useColorModeValue('white', 'gray.100');
  const activeBg = useColorModeValue('cyan.500', 'cyan.700');
  const activeColor = useColorModeValue('white', 'gray.100');
  const textColor = useColorModeValue('gray.700', 'gray.200');

  return (
    <Tooltip label={isCollapsed ? children : ''} placement="right" hasArrow>
      <RouterLink to={to}>
        <Flex
          align="center"
          p="2"
          mx="2"
          borderRadius="lg"
          role="group"
          cursor="pointer"
          bg={isActive ? activeBg : 'transparent'}
          color={isActive ? activeColor : textColor}
          _hover={{
            bg: isActive ? activeBg : hoverBg,
            color: hoverColor,
          }}
        >
          <Icon
            mr={isCollapsed ? "0" : "3"}
            fontSize="16"
            as={icon}
            color={isActive ? activeColor : 'inherit'}
            _groupHover={{
              color: hoverColor,
            }}
          />
          {!isCollapsed && children}
        </Flex>
      </RouterLink>
    </Tooltip>
  );
};

const Sidebar: React.FC = () => {
  const { isCollapsed, toggleSidebar } = useSidebar();
  const bg = useColorModeValue('white', 'gray.800');
  const borderColor = useColorModeValue('gray.200', 'gray.700');

  return (
    <Box
      bg={bg}
      borderRight="1px"
      borderRightColor={borderColor}
      w={isCollapsed ? "16" : "60"}
      pos="fixed"
      h="full"
      transition="width 0.2s"
    >
      <Flex direction="column" h="full">
        <Flex justify="flex-end" p={2}>
          <IconButton
            aria-label={isCollapsed ? "Expand sidebar" : "Collapse sidebar"}
            icon={isCollapsed ? <FiChevronRight /> : <FiChevronLeft />}
            onClick={toggleSidebar}
            variant="ghost"
            size="sm"
          />
        </Flex>
        <VStack spacing={1} align="stretch" py={2}>
          <NavItem icon={FiHome} to="/dashboard" isCollapsed={isCollapsed}>
            Dashboard
          </NavItem>
          <NavItem icon={FiMapPin} to="/monitoring/sites" isCollapsed={isCollapsed}>
            Sites
          </NavItem>
          <NavItem icon={FiMonitor} to="/cameras" isCollapsed={isCollapsed}>
            Cameras
          </NavItem>
          <NavItem icon={FiXOctagon} to="/security-bot" isCollapsed={isCollapsed}>
            Security Bot
          </NavItem>
          <NavItem icon={FiShoppingBag} to="/retail-bot" isCollapsed={isCollapsed}>
            Retail Bot
          </NavItem>
          <NavItem icon={FiUsers} to="/traffic-bot" isCollapsed={isCollapsed}>
            Traffic Bot
          </NavItem>
          <NavItem icon={FiShield} to="/industry-safety-bot" isCollapsed={isCollapsed}>
            Safety Bot
          </NavItem>
          <NavItem icon={FiVideo} to="/surveillance-demo" isCollapsed={isCollapsed}>
            Surveillance Demo
          </NavItem>
          <NavItem icon={FiCrosshair} to="/detect-demo" isCollapsed={isCollapsed}>
            Detection Demo
          </NavItem>
          <NavItem icon={FiAlertCircle} to="/surveillance-rules" isCollapsed={isCollapsed}>
            Surveillance Rules
          </NavItem>
          <NavItem icon={FiUserCheck} to="/surveillance-profiles" isCollapsed={isCollapsed}>
            Surveillance Profiles
          </NavItem>
          <NavItem icon={FiBell} to="/cases" isCollapsed={isCollapsed}>
            Cases
          </NavItem>
          <NavItem icon={FiLink} to="/integrations" isCollapsed={isCollapsed}>
            Integrations
          </NavItem>
          <NavItem icon={FiSettings} to="/settings" isCollapsed={isCollapsed}>
            Settings
          </NavItem>
        </VStack>
      </Flex>
    </Box>
  );
};

export default Sidebar;
