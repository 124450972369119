import React, { useCallback, useState } from 'react';
import {
  Box,
  Container,
  VStack,
  Button,
  Heading,
  Text,
  Image,
  useToast,
  Grid,
  GridItem,
  Card,
  CardBody,
  useColorModeValue,
  IconButton,
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
  Input,
  Textarea,
} from '@chakra-ui/react';
import { useDropzone } from 'react-dropzone';
import { AddIcon, DeleteIcon } from '@chakra-ui/icons';
import { useAuth } from '../contexts/AuthContext';
import api from '../services/api';

interface DetectionResult {
  caption?: any;
  objects?: any;
  ocr?: any;
  regions?: any;
}

const DetectDemo: React.FC = () => {
  const [selectedImage, setSelectedImage] = useState<string | null>(null);
  const [processedImages, setProcessedImages] = useState<{ [key: string]: string }>({});
  const [isProcessing, setIsProcessing] = useState(false);
  const [results, setResults] = useState<DetectionResult | null>(null);
  const [customQuery, setCustomQuery] = useState('');
  const [segmentExpression, setSegmentExpression] = useState('');
  const toast = useToast();
  const { user } = useAuth(); 
  const token = localStorage.getItem('token'); 

  // Move color mode values to component level
  const cardBg = useColorModeValue('white', 'gray.700');
  const resultsBg = useColorModeValue('gray.50', 'gray.700');

  const truncateString = (str: string, maxLength = 50): string => {
    if (typeof str !== 'string') return str;
    return str.length > maxLength 
      ? str.substring(0, maxLength) + '...' 
      : str;
  };

  const filterBase64FromResults = (data: any) => {
    // If data is null or undefined, return an empty object
    if (!data) return {};
    
    const filtered: Record<string, any> = {};
    
    // Process each key in the response
    for (const [key, value] of Object.entries(data)) {
      // Check if the value has a success and data structure
      if (value && typeof value === 'object' && value.success && value.data) {
        // Only add to filtered results if there's meaningful data
        if (value.data.base64_image || value.data.metadata) {
          filtered[key] = value;
        }
      } else {
        // For any other structure, add as-is
        filtered[key] = value;
      }
    }
    
    return filtered;
  };

  const onDrop = useCallback((acceptedFiles: File[]) => {
    const file = acceptedFiles[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        setSelectedImage(reader.result as string);
        setResults(null);
        setProcessedImages({});
      };
      reader.readAsDataURL(file);
    }
  }, []);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: { 'image/*': [] },
    multiple: false,
  });

  const analyzeImage = async (additionalData = {}) => {
    if (!selectedImage) return;

    setIsProcessing(true);
    try {
      // Ensure token is available before making the request
      if (!token) {
        toast({
          title: 'Authentication Error',
          description: 'No authentication token found. Please log in.',
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
        setIsProcessing(false);
        return;
      }

      const response = await api.post('/florence/analyze', {
        image: selectedImage,
        ...additionalData
      }, {
        headers: { 
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      });
      
      // Log the full response for debugging
      console.log('Analyze Full Response:', response.data);
      
      // Process the response
      const processedResults = filterBase64FromResults(response.data || {});
      
      // Ensure we have meaningful results
      const hasValidResults = Object.keys(processedResults).length > 0;
      
      if (hasValidResults) {
        setResults(processedResults);
      } else {
        setResults(null);
        toast({
          title: "No Results",
          description: "The analysis did not return any meaningful data.",
          status: "warning",
          duration: 3000,
          isClosable: true,
        });
      }
    } catch (error: any) {
      console.error('Analyze Image Error:', error);
      
      // More detailed error logging
      if (error.response) {
        console.error('Response Error:', error.response.data);
        console.error('Response Status:', error.response.status);
      }
      
      toast({
        title: 'Error processing image',
        description: error.response?.data?.detail || 'An error occurred while processing the image.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
      setResults(null);
    } finally {
      setIsProcessing(false);
    }
  };

  const handleDetectCustom = async () => {
    try {
      // Ensure token is available before making the request
      if (!token) {
        toast({
          title: 'Authentication Error',
          description: 'No authentication token found. Please log in.',
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
        setIsProcessing(false);
        return;
      }

      setIsProcessing(true);
      const response = await api.post('/florence/detect-custom', {
        image: selectedImage,
        query: customQuery
      }, {
        headers: { 
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      });
      
      // Log the full response for debugging
      console.log('Detect Custom Full Response:', response);
      console.log('Detect Custom Response Data:', response.data);
      
      // Safely extract data, handling the nested response structure
      const processedResults = filterBase64FromResults(response.data || {});
      
      // Ensure we have meaningful results
      const hasValidResults = processedResults && 
        (processedResults.base64_image || 
         processedResults.annotated_image || 
         processedResults.processed_image || 
         processedResults.metadata || 
         Object.keys(processedResults).length > 0);
      
      if (hasValidResults) {
        setResults(processedResults);
      } else {
        setResults(null);
        toast({
          title: "No Results",
          description: "The analysis did not return any meaningful data.",
          status: "warning",
          duration: 3000,
          isClosable: true,
        });
      }
    } catch (error: any) {
      console.error("Custom Detection Error:", error);
      
      // More detailed error logging
      if (error.response) {
        console.error('Response Error:', error.response.data);
        console.error('Response Status:', error.response.status);
      }
      
      toast({
        title: "Detection Error",
        description: error.response?.data?.detail || 'An unknown error occurred',
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      setResults(null);
    } finally {
      setIsProcessing(false);
    }
  };

  const handleSegmentation = async () => {
    try {
      // Ensure token is available before making the request
      if (!token) {
        toast({
          title: 'Authentication Error',
          description: 'No authentication token found. Please log in.',
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
        setIsProcessing(false);
        return;
      }

      setIsProcessing(true);
      const response = await api.post('/florence/segment', {
        image: selectedImage,
        expression: segmentExpression
      }, {
        headers: { 
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      });
      
      // Log the full response for debugging
      console.log('Segmentation Full Response:', response);
      console.log('Segmentation Response Data:', response.data);
      
      // Safely extract data, handling the nested response structure
      const processedResults = filterBase64FromResults(response.data || {});
      
      // Ensure we have meaningful results
      const hasValidResults = processedResults && 
        (processedResults.base64_image || 
         processedResults.annotated_image || 
         processedResults.processed_image || 
         processedResults.metadata || 
         Object.keys(processedResults).length > 0);
      
      if (hasValidResults) {
        setResults(processedResults);
      } else {
        setResults(null);
        toast({
          title: "No Results",
          description: "The analysis did not return any meaningful data.",
          status: "warning",
          duration: 3000,
          isClosable: true,
        });
      }
    } catch (error: any) {
      console.error("Segmentation Error:", error);
      
      // More detailed error logging
      if (error.response) {
        console.error('Response Error:', error.response.data);
        console.error('Response Status:', error.response.status);
      }
      
      toast({
        title: "Segmentation Error",
        description: error.response?.data?.detail || 'An unknown error occurred',
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      setResults(null);
    } finally {
      setIsProcessing(false);
    }
  };

  const clearImage = () => {
    setSelectedImage(null);
    setResults(null);
  };

  return (
    <Container maxW="container.xl" py={5}>
      <VStack spacing={6} align="stretch">
        {/* Upload Section */}
        <Card bg={cardBg}>
          <CardBody>
            <VStack spacing={4}>
              <Heading size="md">Image Upload</Heading>
              {selectedImage ? (
                <Box position="relative" width="100%">
                  <Image
                    src={selectedImage}
                    alt="Uploaded"
                    maxH="400px"
                    objectFit="contain"
                    margin="0 auto"
                  />
                  <IconButton
                    aria-label="Remove image"
                    icon={<DeleteIcon />}
                    position="absolute"
                    top={2}
                    right={2}
                    onClick={clearImage}
                  />
                </Box>
              ) : (
                <Box
                  {...getRootProps()}
                  p={10}
                  border="2px dashed"
                  borderColor="gray.300"
                  borderRadius="md"
                  cursor="pointer"
                  width="100%"
                >
                  <input {...getInputProps()} />
                  <VStack spacing={2}>
                    <AddIcon />
                    <Text>Drop an image here or click to select</Text>
                  </VStack>
                </Box>
              )}

              <Tabs width="100%">
                <TabList>
                  <Tab>General Analysis</Tab>
                  <Tab>Custom Detection</Tab>
                  <Tab>Segmentation</Tab>
                </TabList>

                <TabPanels>
                  <TabPanel>
                    <VStack spacing={4} width="100%">
                      <Button
                        colorScheme="blue"
                        onClick={() => analyzeImage()}
                        isLoading={isProcessing}
                        isDisabled={!selectedImage}
                        width="100%"
                      >
                        Analyze Image
                      </Button>

                      {/* Display all base64 images for General Analysis */}
                      {results && Object.entries(results).map(([key, result]) => (
                        result.success && result.data?.base64_image && (
                          <Box key={key} width="100%">
                            <VStack spacing={3} align="stretch">
                              <Heading size="sm" textTransform="capitalize">{key} Result</Heading>
                              <Image
                                src={result.data.base64_image.startsWith('data:') 
                                  ? result.data.base64_image 
                                  : `data:image/jpeg;base64,${result.data.base64_image}`}
                                alt={`${key} result`}
                                maxH="400px"
                                objectFit="contain"
                              />
                              {result.data?.metadata && (
                                <Box 
                                  p={3} 
                                  bg={resultsBg}
                                  borderRadius="md"
                                  overflowX="auto"
                                >
                                  {key === 'caption' && (
                                    <Text>{result.data.metadata.caption}</Text>
                                  )}
                                  {key === 'ocr' && (
                                    <Text whiteSpace="pre-wrap">{result.data.metadata.text}</Text>
                                  )}
                                  {key === 'objects' && result.data.metadata.detections && (
                                    <pre>{JSON.stringify(result.data.metadata.detections, null, 2)}</pre>
                                  )}
                                </Box>
                              )}
                            </VStack>
                          </Box>
                        )
                      ))}
                    </VStack>
                  </TabPanel>

                  <TabPanel>
                    <VStack spacing={4}>
                      <Input
                        placeholder="Enter detection query (e.g., 'find all cars')"
                        value={customQuery}
                        onChange={(e) => setCustomQuery(e.target.value)}
                      />
                      <Button
                        colorScheme="blue"
                        onClick={handleDetectCustom}
                        isLoading={isProcessing}
                        isDisabled={!selectedImage || !customQuery}
                        width="100%"
                      >
                        Custom Detection
                      </Button>
                    </VStack>
                  </TabPanel>

                  <TabPanel>
                    <VStack spacing={4}>
                      <Textarea
                        placeholder="Enter segmentation expression"
                        value={segmentExpression}
                        onChange={(e) => setSegmentExpression(e.target.value)}
                      />
                      <Button
                        colorScheme="blue"
                        onClick={handleSegmentation}
                        isLoading={isProcessing}
                        isDisabled={!selectedImage || !segmentExpression}
                        width="100%"
                      >
                        Segment Image
                      </Button>
                    </VStack>
                  </TabPanel>
                </TabPanels>
              </Tabs>
            </VStack>
          </CardBody>
        </Card>

        {/* Results Section - Only show for Custom Detection and Segmentation */}
        {results && (
          <VStack spacing={4} width="100%">
            {/* Only show processed images for non-General Analysis tabs */}
            {Object.entries(processedImages).map(([key, base64]) => (
              base64 && (
                <Card key={key} bg={cardBg} width="100%">
                  <CardBody>
                    <VStack spacing={3}>
                      <Heading size="sm" textTransform="capitalize">{key} Result</Heading>
                      <Image
                        src={base64.startsWith('data:') ? base64 : `data:image/jpeg;base64,${base64}`}
                        alt={`${key} result`}
                        maxH="400px"
                        objectFit="contain"
                      />
                      {/* Show the metadata for this result */}
                      {results[key]?.data?.metadata && (
                        <Box width="100%">
                          <Text fontWeight="bold" mb={2}>Metadata:</Text>
                          <Box 
                            p={3} 
                            bg={resultsBg}
                            borderRadius="md"
                            overflowX="auto"
                          >
                            {key === 'caption' && (
                              <Text>{results[key].data.metadata.caption}</Text>
                            )}
                            {key === 'ocr' && (
                              <Text whiteSpace="pre-wrap">{results[key].data.metadata.text}</Text>
                            )}
                            {key === 'objects' && results[key].data.metadata.detections && (
                              <pre>{JSON.stringify(results[key].data.metadata.detections, null, 2)}</pre>
                            )}
                          </Box>
                        </Box>
                      )}
                    </VStack>
                  </CardBody>
                </Card>
              )
            ))}

            {/* Display full JSON results */}
            <Card bg={cardBg} width="100%">
              <CardBody>
                <VStack spacing={3} align="stretch">
                  <Heading size="sm">Full Analysis Results</Heading>
                  <Box 
                    p={4} 
                    bg={resultsBg}
                    borderRadius="md"
                    overflowX="auto"
                  >
                    {results ? (
                      <Box 
                        whiteSpace="pre-wrap" 
                        wordBreak="break-word"
                        fontFamily="monospace"
                      >
                        {JSON.stringify(
                          results.data && results.data.success !== undefined
                            ? { 
                                success: results.data.success, 
                                data: results.data.data, 
                                error: results.data.error 
                              }
                            : results, 
                          null, 
                          2
                        )}
                      </Box>
                    ) : (
                      <Text color="gray.500" fontStyle="italic">
                        No analysis results available
                      </Text>
                    )}
                  </Box>
                </VStack>
              </CardBody>
            </Card>
          </VStack>
        )}
      </VStack>
    </Container>
  );
};

export default DetectDemo;
