import React, { useState, useEffect, useCallback } from 'react';
import {
  Box,
  Heading,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Button,
  useToast,
  Switch,
  HStack,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  FormControl,
  FormLabel,
  Input,
  Select,
  NumberInput,
  NumberInputField,
  Textarea,
} from '@chakra-ui/react';
import { FiPlus } from 'react-icons/fi';
import { surveillanceRulesApi } from '../services/api';

interface Parameters {
  field: string;
  operator: string;
  value: number;
  [key: string]: any;
}

interface SurveillanceRule {
  id: number;
  name: string;
  description: string;
  type: string;
  parameters: Parameters;
  is_active: boolean;
  created_at: string;
  updated_at: string;
}

interface SurveillanceRuleCreate {
  name: string;
  description: string;
  type: string;
  parameters: Parameters;
}

const FIELDS = [
  { value: 'people_count', label: 'People Count' },
  { value: 'real_person_count', label: 'Real Person Count' },
  { value: 'person_sitting', label: 'Person Sitting' },
  { value: 'person_lying_down', label: 'Person Lying Down' },
  { value: 'helmet_count', label: 'Helmet Count' },
  { value: 'face_mask_count', label: 'Face Mask Count' },
  { value: 'vehicle_count', label: 'Vehicle Count' },
  { value: 'face_count', label: 'Face Count' },
  { value: 'atm_count', label: 'ATM Count' },
  { value: 'bag_count', label: 'Bag Count' },
];

const OPERATORS = [
  { value: '>', label: 'Greater Than' },
  { value: '<', label: 'Less Than' },
  { value: '=', label: 'Equal To' },
  { value: '!=', label: 'Not Equal To' },
];

const RULE_TYPES = [
  { value: 'motion_detection', label: 'Motion Detection' },
  { value: 'person_detection', label: 'Person Detection' },
  { value: 'object_detection', label: 'Object Detection' },
  { value: 'tampering_detection', label: 'Tampering Detection' },
  { value: 'loitering_detection', label: 'Loitering Detection' },
];

const SurveillanceRules = () => {
  const [rules, setRules] = useState<SurveillanceRule[]>([]);
  const [newRule, setNewRule] = useState<SurveillanceRuleCreate>({
    name: '',
    description: '',
    type: '',
    parameters: {
      field: '',
      operator: '',
      value: 0,
    },
  });
  const [editingRule, setEditingRule] = useState<SurveillanceRule | null>(null);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const toast = useToast();

  const resetForm = () => {
    setNewRule({
      name: '',
      description: '',
      type: '',
      parameters: {
        field: '',
        operator: '',
        value: 0,
      },
    });
    setEditingRule(null);
  };

  const handleModalClose = () => {
    resetForm();
    onClose();
  };

  const fetchRules = useCallback(async () => {
    try {
      const data = await surveillanceRulesApi.getRules();
      setRules(data);
    } catch (error) {
      toast({
        title: 'Error fetching rules',
        description: 'Unable to load surveillance rules',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  }, [toast]);

  useEffect(() => {
    fetchRules();
  }, [fetchRules]);

  const handleCreateRule = async () => {
    try {
      await surveillanceRulesApi.createRule(newRule);
      toast({
        title: 'Success',
        description: 'Rule created successfully',
        status: 'success',
        duration: 5000,
        isClosable: true,
      });
      onClose();
      fetchRules();
      setNewRule({
        name: '',
        description: '',
        type: '',
        parameters: {
          field: '',
          operator: '',
          value: 0,
        },
      });
    } catch (error) {
      toast({
        title: 'Error creating rule',
        description: 'Unable to create surveillance rule',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const handleToggleRule = async (id: number) => {
    try {
      const rule = rules.find(r => r.id === id);
      if (rule) {
        await surveillanceRulesApi.updateRule(id, {
          is_active: !rule.is_active
        });
        fetchRules();
      }
    } catch (error) {
      toast({
        title: 'Error toggling rule',
        description: 'Unable to toggle rule status',
        status: 'error',
        duration: 5000,
        isClosable: true
      });
    }
  };

  const handleEditRule = (rule: SurveillanceRule) => {
    setEditingRule(rule);
    setNewRule({
      name: rule.name,
      description: rule.description,
      type: rule.type,
      parameters: rule.parameters,
    });
    onOpen();
  };

  const handleSaveRule = async () => {
    try {
      if (editingRule) {
        await surveillanceRulesApi.updateRule(editingRule.id, newRule);
        toast({
          title: 'Success',
          description: 'Rule updated successfully',
          status: 'success',
          duration: 5000,
          isClosable: true,
        });
      } else {
        await surveillanceRulesApi.createRule(newRule);
        toast({
          title: 'Success',
          description: 'Rule created successfully',
          status: 'success',
          duration: 5000,
          isClosable: true,
        });
      }
      handleModalClose();
      fetchRules();
    } catch (error) {
      toast({
        title: editingRule ? 'Error updating rule' : 'Error creating rule',
        description: `Unable to ${editingRule ? 'update' : 'create'} surveillance rule`,
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  return (
    <Box p={4}>
      <HStack justify="space-between" mb={6}>
        <Heading size="lg">Surveillance Rules</Heading>
        <Button leftIcon={<FiPlus />} colorScheme="blue" onClick={onOpen}>
          Add Rule
        </Button>
      </HStack>

      <Table variant="simple">
        <Thead>
          <Tr>
            <Th>Name</Th>
            <Th>Description</Th>
            <Th>Type</Th>
            <Th>Field</Th>
            <Th>Operator</Th>
            <Th>Value</Th>
            <Th>Status</Th>
          </Tr>
        </Thead>
        <Tbody>
          {rules.map((rule) => {
            return (
              <Tr key={rule.id}>
                <Td>{rule.name}</Td>
                <Td>{rule.description}</Td>
                <Td>
                  {RULE_TYPES.find((t) => t.value === rule.type)?.label ?? rule.type}
                </Td>
                <Td>
                  {FIELDS.find((f) => f.value === rule.parameters.field)?.label ?? rule.parameters.field}
                </Td>
                <Td>
                  {OPERATORS.find((o) => o.value === rule.parameters.operator)?.label ?? rule.parameters.operator}
                </Td>
                <Td>{rule.parameters.value}</Td>
                <Td>
                  <HStack spacing={2}>
                    <Switch
                      isChecked={rule.is_active}
                      onChange={() => handleToggleRule(rule.id)}
                    />
                    <Button size="sm" onClick={() => handleEditRule(rule)}>
                      Edit
                    </Button>
                  </HStack>
                </Td>
              </Tr>
            );
          })}
        </Tbody>
      </Table>

      <Modal isOpen={isOpen} onClose={handleModalClose} size="xl">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{editingRule ? 'Edit Rule' : 'Create New Rule'}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <FormControl mb={4}>
              <FormLabel>Name</FormLabel>
              <Input
                value={newRule.name}
                onChange={(e) => setNewRule({ ...newRule, name: e.target.value })}
              />
            </FormControl>
            <FormControl mb={4}>
              <FormLabel>Description</FormLabel>
              <Textarea
                value={newRule.description}
                onChange={(e) => setNewRule({ ...newRule, description: e.target.value })}
              />
            </FormControl>
            <FormControl mb={4}>
              <FormLabel>Type</FormLabel>
              <Select
                value={newRule.type}
                onChange={(e) => setNewRule({ ...newRule, type: e.target.value })}
              >
                <option value="">Select Type</option>
                {RULE_TYPES.map((type) => (
                  <option key={type.value} value={type.value}>
                    {type.label}
                  </option>
                ))}
              </Select>
            </FormControl>
            <FormControl mb={4}>
              <FormLabel>Field</FormLabel>
              <Select
                value={newRule.parameters.field}
                onChange={(e) => setNewRule({
                  ...newRule,
                  parameters: { ...newRule.parameters, field: e.target.value }
                })}
              >
                <option value="">Select Field</option>
                {FIELDS.map((field) => (
                  <option key={field.value} value={field.value}>
                    {field.label}
                  </option>
                ))}
              </Select>
            </FormControl>
            <FormControl mb={4}>
              <FormLabel>Operator</FormLabel>
              <Select
                value={newRule.parameters.operator}
                onChange={(e) => setNewRule({
                  ...newRule,
                  parameters: { ...newRule.parameters, operator: e.target.value }
                })}
              >
                <option value="">Select Operator</option>
                {OPERATORS.map((operator) => (
                  <option key={operator.value} value={operator.value}>
                    {operator.label}
                  </option>
                ))}
              </Select>
            </FormControl>
            <FormControl mb={4}>
              <FormLabel>Value</FormLabel>
              <NumberInput min={0}>
                <NumberInputField
                  value={newRule.parameters.value}
                  onChange={(e) => setNewRule({
                    ...newRule,
                    parameters: { ...newRule.parameters, value: Number(e.target.value) }
                  })}
                />
              </NumberInput>
            </FormControl>
          </ModalBody>
          <ModalFooter>
            <Button variant="ghost" mr={3} onClick={handleModalClose}>
              Cancel
            </Button>
            <Button colorScheme="blue" onClick={handleSaveRule}>
              {editingRule ? 'Save Changes' : 'Create'}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default SurveillanceRules;
