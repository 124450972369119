import React from 'react';
import {
  Drawer,
  DrawerBody,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  VStack,
  Box,
  Text,
  Badge,
  IconButton,
  Flex,
  useColorModeValue,
} from '@chakra-ui/react';
import { CheckIcon } from '@chakra-ui/icons';
import { useNotifications } from '../contexts/NotificationsContext';
import { format } from 'date-fns';

interface NotificationsDrawerProps {
  isOpen: boolean;
  onClose: () => void;
}

const NotificationsDrawer: React.FC<NotificationsDrawerProps> = ({ isOpen, onClose }) => {
  const { notifications, markAsRead } = useNotifications();
  const bgColor = useColorModeValue('white', 'gray.800');
  const borderColor = useColorModeValue('gray.200', 'gray.700');
  const textColor = useColorModeValue('gray.800', 'whiteAlpha.900');
  const subTextColor = useColorModeValue('gray.600', 'whiteAlpha.800');
  const dateColor = useColorModeValue('gray.500', 'whiteAlpha.700');
  const unreadBgColor = useColorModeValue('blue.50', 'blue.900');
  const emptyTextColor = useColorModeValue('gray.500', 'whiteAlpha.600');
  const hoverBorderColor = useColorModeValue('blue.200', 'blue.600');
  const hoverButtonBg = useColorModeValue('blue.100', 'blue.700');

  const getStatusColor = (type: string) => {
    switch (type.toLowerCase()) {
      case 'success':
        return 'green';
      case 'error':
        return 'red';
      case 'warning':
        return 'orange';
      case 'info':
        return 'blue';
      default:
        return 'gray';
    }
  };

  return (
    <Drawer isOpen={isOpen} placement="right" onClose={onClose} size="md">
      <DrawerOverlay />
      <DrawerContent bg={bgColor}>
        <DrawerCloseButton color={textColor} />
        <DrawerHeader borderBottomWidth="1px" borderBottomColor={borderColor} color={textColor}>
          Notifications
        </DrawerHeader>
        <DrawerBody>
          <VStack spacing={4} align="stretch" pt={4}>
            {notifications.length === 0 ? (
              <Text color={emptyTextColor} textAlign="center">No notifications</Text>
            ) : (
              notifications.map((notification) => (
                <Box
                  key={notification.id}
                  p={4}
                  borderWidth="1px"
                  borderRadius="lg"
                  borderColor={borderColor}
                  bg={notification.is_read ? bgColor : unreadBgColor}
                  position="relative"
                  transition="all 0.2s"
                  _hover={{
                    borderColor: hoverBorderColor,
                    boxShadow: 'sm'
                  }}
                >
                  <Flex justify="space-between" align="center" mb={2}>
                    <Badge colorScheme={getStatusColor(notification.type)}>
                      {notification.type}
                    </Badge>
                    <Text fontSize="sm" color={dateColor}>
                      {format(new Date(notification.created_at), 'MMM d, h:mm a')}
                    </Text>
                  </Flex>
                  <Text fontWeight="bold" mb={1} color={textColor}>
                    {notification.title}
                  </Text>
                  <Text color={subTextColor}>{notification.message}</Text>
                  {!notification.is_read && (
                    <IconButton
                      aria-label="Mark as read"
                      icon={<CheckIcon />}
                      size="sm"
                      position="absolute"
                      top={2}
                      right={2}
                      colorScheme="blue"
                      variant="ghost"
                      onClick={() => markAsRead(notification.id)}
                      _hover={{
                        bg: hoverButtonBg
                      }}
                    />
                  )}
                </Box>
              ))
            )}
          </VStack>
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
};

export default NotificationsDrawer;
