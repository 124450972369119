import React from 'react';
import {
  Box,
  Container,
  VStack,
  Heading,
  useToast,
  Drawer,
  DrawerBody,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  useDisclosure,
  useColorModeValue,
  Button,
  HStack,
  Image,
  SimpleGrid,
  Text,
} from '@chakra-ui/react';
import ChatInput from '../components/ChatInput';
import ChatMessage from '../components/ChatMessage';
import RulesInput from '../components/RulesInput';
import { Message } from '../types/chat';
import { industrySafetyRules } from '../constants/industrySafetyRules';
import { useAuth } from '../contexts/AuthContext';
import { useChatState } from '../hooks/useChatState';
import { DeleteIcon } from '@chakra-ui/icons';
import api from '../services/api';

const BOT_TYPE = 'industry-safety';

const IndustrySafetyBot: React.FC = () => {
  const { isOpen: isRulesOpen, onOpen: onRulesOpen, onClose: onRulesClose } = useDisclosure();
  const { isOpen: isSamplesOpen, onOpen: onSamplesOpen, onClose: onSamplesClose } = useDisclosure();
  const { chatState, setChatState, clearChat } = useChatState(BOT_TYPE);
  const [selectedRules, setSelectedRules] = React.useState<string[]>([]);
  const [selectedSampleImage, setSelectedSampleImage] = React.useState<string | null>(null);
  const toast = useToast();
  const { user } = useAuth();

  const bg = useColorModeValue('white', 'gray.800');
  const borderColor = useColorModeValue('gray.200', 'gray.600');
  const drawerBg = useColorModeValue('white', 'gray.800');
  const drawerColor = useColorModeValue('gray.800', 'white');

  // Sample images data
  const sampleImages = Array.from({ length: 20 }, (_, i) => ({
    id: i + 1,
    url: `/industry/Industry${i + 1}.jpg`,
    title: `Industry Safety Sample ${i + 1}`
  }));

  const handleSampleImageSelect = (imageUrl: string) => {
    setSelectedSampleImage(imageUrl);
    onSamplesClose();
  };

  const handleSendMessage = async (text: string, image?: File) => {
    try {
      setChatState((prevState) => ({ ...prevState, isLoading: true, error: null }));

      let base64Image: string | undefined;
      if (image) {
        const reader = new FileReader();
        base64Image = await new Promise((resolve, reject) => {
          reader.onload = () => resolve(reader.result as string);
          reader.onerror = reject;
          reader.readAsDataURL(image);
        });
      }

      const requestData = {
        message: text,
        image: base64Image,
        user_id: user?.id?.toString() || '',
        username: user?.username || '',
        role: user?.role || '',
        rules: selectedRules || []
      };

      const userMessage: Message = {
        role: 'user',
        content: text,
        image: image ? URL.createObjectURL(image) : undefined,
      };

      setChatState((prevState) => ({
        ...prevState,
        messages: [...prevState.messages, userMessage],
      }));

      const response = await api.post('/ai/chat/industrysafetycompletions', requestData, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
      });

      // Create the assistant message with analysis
      const assistantMessage: Message = {
        role: response.data.role,
        content: response.data.content,
        jsonData: JSON.parse(response.data.jsonData),
        od_image: response.data.od_image,
        od_response: response.data.od_response
      };

      setChatState((prevState) => ({
        ...prevState,
        messages: [...prevState.messages, assistantMessage],
        isLoading: false,
      }));
    } catch (error) {
      console.error('Error sending message:', error);
      toast({
        title: 'Error',
        description: 'Failed to send message. Please try again.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
      setChatState((prevState) => ({ ...prevState, isLoading: false, error }));
    }
  };

  const handleAddRule = React.useCallback((rule: string) => {
    if (!selectedRules.includes(rule)) {
      setSelectedRules((prevRules) => [...prevRules, rule]);
    }
  }, [selectedRules]);

  const handleRemoveRule = React.useCallback((rule: string) => {
    setSelectedRules((prevRules) => prevRules.filter((r) => r !== rule));
  }, [selectedRules]);

  const handleClearChat = async () => {
    await clearChat();
    toast({
      title: 'Chat Cleared',
      description: 'All messages have been cleared.',
      status: 'success',
      duration: 3000,
      isClosable: true,
    });
  };

  return (
    <Container maxW="container.xl" p={4}>
      <Box
        h="calc(100vh - 150px)"
        bg={bg}
        borderRadius="lg"
        border="1px"
        borderColor={borderColor}
        overflow="hidden"
        display="flex"
        flexDirection="column"
      >
        <Box p={4} borderBottom="1px" borderColor={borderColor}>
          <HStack justify="space-between">
            <Heading size="md">Industry Safety Monitoring Agent</Heading>
            <Button
              leftIcon={<DeleteIcon />}
              colorScheme="red"
              variant="ghost"
              size="sm"
              onClick={handleClearChat}
            >
              Clear Chat
            </Button>
          </HStack>
        </Box>

        <Box flex="1" overflowY="auto" p={4}>
          <VStack spacing={4} align="stretch">
            {chatState.messages.map((message, index) => (
              <ChatMessage key={index} message={message} />
            ))}
          </VStack>
        </Box>

        <Box p={4} borderTop="1px" borderColor={borderColor}>
          <ChatInput
            onSendMessage={handleSendMessage}
            isLoading={chatState.isLoading}
            onRulesClick={onRulesOpen}
            onSamplesClick={onSamplesOpen}
            selectedRules={selectedRules}
            selectedSampleImage={selectedSampleImage}
          />
        </Box>
      </Box>

      <Drawer isOpen={isRulesOpen} placement="right" onClose={onRulesClose} size="md">
        <DrawerOverlay />
        <DrawerContent bg={drawerBg} color={drawerColor}>
          <DrawerCloseButton />
          <DrawerHeader>Industry Safety Rules</DrawerHeader>
          <DrawerBody>
            <RulesInput
              rules={industrySafetyRules}
              selectedRules={selectedRules}
              onAddRule={handleAddRule}
              onRemoveRule={handleRemoveRule}
            />
          </DrawerBody>
        </DrawerContent>
      </Drawer>

      <Drawer isOpen={isSamplesOpen} placement="right" onClose={onSamplesClose} size="lg">
        <DrawerOverlay />
        <DrawerContent bg={drawerBg} color={drawerColor}>
          <DrawerCloseButton />
          <DrawerHeader>Select Sample Image</DrawerHeader>
          <DrawerBody>
            <SimpleGrid columns={2} spacing={4}>
              {sampleImages.map((image) => (
                <Box
                  key={image.id}
                  cursor="pointer"
                  onClick={() => handleSampleImageSelect(image.url)}
                  borderWidth="1px"
                  borderRadius="lg"
                  p={2}
                  _hover={{ shadow: 'md' }}
                >
                  <Image src={image.url} alt={image.title} borderRadius="md" />
                  <Text mt={2} fontSize="sm" textAlign="center">
                    {image.title}
                  </Text>
                </Box>
              ))}
            </SimpleGrid>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </Container>
  );
};

export default IndustrySafetyBot;
