import React from 'react';
import {
  ChakraProvider,
  Box,
  Flex,
  useDisclosure,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  DrawerHeader,
  DrawerBody,
  ColorModeScript,
  extendTheme,
  ThemeConfig,
  useColorModeValue,
} from '@chakra-ui/react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import Navbar from './components/Navbar';
import Sidebar from './components/Sidebar';
import Dashboard from './pages/Dashboard';
import Cameras from './pages/Cameras';
import CameraBot from './pages/CameraBot';
import RetailBot from './pages/RetailBot';
import TrafficBot from './pages/TrafficBot';
import IndustrySafetyBot from './pages/IndustrySafetyBot';
import SurveillanceDemo from './pages/SurveillanceDemo';
import DetectDemo from './pages/DetectDemo';
import Chat from './pages/Chat';
import Cases from './pages/Cases';
import Settings from './pages/Settings';
import IntegrationSettings from './components/IntegrationSettings';
import Notifications from './pages/Notifications';
import NotificationsDrawer from './components/NotificationsDrawer';
import { NotificationsProvider } from './contexts/NotificationsContext';
import { ValidationCriteriaProvider } from './contexts/ValidationCriteriaContext';
import { AuthProvider } from './contexts/AuthContext';
import Login from './pages/Login';
import Register from './pages/Register';
import { ProtectedRoute } from './components/ProtectedRoute';
import SurveillanceRules from './pages/SurveillanceRules';
import AgentManagement from './pages/AgentManagement';
import CameraAgentAssignment from './pages/CameraAgentAssignment';
import MonitoringPage from './pages/MonitoringPage';
import { SidebarProvider, useSidebar } from './contexts/SidebarContext';

const config: ThemeConfig = {
  initialColorMode: 'system',
  useSystemColorMode: true,
};

const theme = extendTheme({
  config,
  styles: {
    global: (props: any) => ({
      body: {
        bg: props.colorMode === 'dark' ? 'gray.900' : 'white',
        color: props.colorMode === 'dark' ? 'white' : 'gray.800',
      },
    }),
  },
  components: {
    Button: {
      baseStyle: (props: any) => ({
        _hover: {
          bg: props.colorMode === 'dark' ? 'gray.700' : 'gray.100',
        },
      }),
    },
    Modal: {
      baseStyle: (props: any) => ({
        dialog: {
          bg: props.colorMode === 'dark' ? 'gray.800' : 'white',
        },
      }),
    },
    Drawer: {
      baseStyle: (props: any) => ({
        dialog: {
          bg: props.colorMode === 'dark' ? 'gray.800' : 'white',
        },
      }),
    },
  },
});

const EXPANDED_SIDEBAR_WIDTH = "240px";
const COLLAPSED_SIDEBAR_WIDTH = "64px";

const AppLayout: React.FC<{ isOpen: boolean; onOpen: () => void; onClose: () => void }> = ({
  isOpen,
  onOpen,
  onClose,
}) => {
  const { isCollapsed } = useSidebar();
  const sidebarBg = useColorModeValue('white', 'gray.800');

  return (
    <Box>
      <Navbar onSidebarOpen={onOpen} />
      <Flex>
        {/* Desktop Sidebar */}
        <Box
          as="nav"
          pos="fixed"
          top="64px"
          left="0"
          h="calc(100vh - 64px)"
          w={isCollapsed ? COLLAPSED_SIDEBAR_WIDTH : EXPANDED_SIDEBAR_WIDTH}
          bg={sidebarBg}
          borderRightWidth="1px"
          display={{ base: 'none', md: 'block' }}
          zIndex="sticky"
          transition="width 0.2s"
        >
          <Sidebar />
        </Box>

        {/* Mobile Drawer */}
        <Drawer
          isOpen={isOpen}
          placement="left"
          onClose={onClose}
          size="xs"
        >
          <DrawerOverlay />
          <DrawerContent>
            <DrawerCloseButton />
            <DrawerHeader>Menu</DrawerHeader>
            <DrawerBody p={0}>
              <Sidebar />
            </DrawerBody>
          </DrawerContent>
        </Drawer>

        {/* Main Content */}
        <Box
          ml={{ base: 0, md: isCollapsed ? COLLAPSED_SIDEBAR_WIDTH : EXPANDED_SIDEBAR_WIDTH }}
          transition="margin-left 0.2s"
          w="full"
          minH="calc(100vh - 64px)"
          mt="64px"
          p={6}
        >
          <Routes>
            <Route path="/" element={<Navigate to="/dashboard" />} />
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/monitoring/sites" element={<MonitoringPage />} />
            <Route path="/cameras" element={<Cameras />} />
            <Route path="/security-bot" element={<CameraBot />} />
            <Route path="/retail-bot" element={<RetailBot />} />
            <Route path="/traffic-bot" element={<TrafficBot />} />
            <Route path="/industry-safety-bot" element={<IndustrySafetyBot />} />
            <Route path="/surveillance-demo" element={<SurveillanceDemo />} />
            <Route path="/detect-demo" element={<DetectDemo />} />
            <Route path="/surveillance-rules" element={<SurveillanceRules />} />
            <Route path="/surveillance-profiles" element={<AgentManagement />} />
            <Route path="/cases" element={<Cases />} />
            <Route path="/integrations" element={<IntegrationSettings />} />
            <Route path="/settings" element={<Settings />} />
          </Routes>
        </Box>
      </Flex>
    </Box>
  );
};

const App: React.FC = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <ChakraProvider theme={theme}>
      <ColorModeScript initialColorMode={theme.config.initialColorMode} />
      <Router>
        <AuthProvider>
          <NotificationsProvider>
            <ValidationCriteriaProvider>
              <SidebarProvider>
                <Box minH="100vh">
                  <Routes>
                    <Route path="/login" element={<Login />} />
                    <Route path="/register" element={<Register />} />
                    <Route
                      path="/*"
                      element={
                        <ProtectedRoute>
                          <AppLayout isOpen={isOpen} onOpen={onOpen} onClose={onClose} />
                        </ProtectedRoute>
                      }
                    />
                  </Routes>
                  <NotificationsDrawer />
                </Box>
              </SidebarProvider>
            </ValidationCriteriaProvider>
          </NotificationsProvider>
        </AuthProvider>
      </Router>
    </ChakraProvider>
  );
};

export default App;
