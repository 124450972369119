export const industrySafetyRules = [
  {
    title: 'Worker Safety',
    items: [
      'Count total workers and those complying with PPE',
      'Monitor helmet, safety vest, goggles, and gloves usage',
      'Count unsafe acts and hazardous materials',
      'Track running equipment and blocked emergency exits',
      'Identify fire hazards and chemical spills',
      'Check for electrical hazards and missing machinery guards',
      'Verify warning signs and first aid station visibility',
      'Check emergency equipment accessibility',
      'Monitor for trip hazards and confined space violations',
      'Identify lifting hazards and ventilation issues'
    ]
  }
];
