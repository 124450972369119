import React from 'react';
import {
  Box,
  Flex,
  IconButton,
  useColorModeValue,
  Text,
  HStack,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Avatar,
  Badge,
  useColorMode,
  Button,
  MenuDivider,
  useDisclosure,
} from '@chakra-ui/react';
import { FiBell, FiMenu, FiMoon, FiSun, FiUser, FiLogOut } from 'react-icons/fi';
import { useNotifications } from '../contexts/NotificationsContext';
import { useAuth } from '../contexts/AuthContext';
import NotificationsDrawer from './NotificationsDrawer';

interface NavbarProps {
  onSidebarOpen?: () => void;
}

const Navbar: React.FC<NavbarProps> = ({ onSidebarOpen }) => {
  const { notifications, unreadCount, markAsRead } = useNotifications();
  const { user, logout } = useAuth();
  const { colorMode, toggleColorMode } = useColorMode();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const bg = useColorModeValue('white', 'gray.900');
  const borderColor = useColorModeValue('gray.200', 'gray.700');
  const textColor = useColorModeValue('gray.800', 'white');
  const iconColor = useColorModeValue('gray.600', 'gray.400');
  const hoverBg = useColorModeValue('gray.100', 'gray.700');
  const menuBg = useColorModeValue('white', 'gray.800');

  return (
    <>
      <Box
        bg={bg}
        px={4}
        borderBottom="1px"
        borderStyle="solid"
        borderColor={borderColor}
        position="fixed"
        top={0}
        left={0}
        right={0}
        zIndex={10}
        h="64px"
      >
        <Flex h={16} alignItems="center" justifyContent="space-between">
          <HStack spacing={8} alignItems="center">
            <IconButton
              size="md"
              icon={<FiMenu />}
              aria-label="Open Menu"
              display={{ base: 'inherit', md: 'none' }}
              onClick={onSidebarOpen}
              variant="ghost"
              color={iconColor}
              _hover={{ bg: hoverBg }}
            />
            <Text fontSize="xl" fontWeight="bold" color={textColor}>
              Pixuate - AI Agents Demo
            </Text>
          </HStack>

          <HStack spacing={4}>
            <IconButton
              icon={colorMode === 'light' ? <FiMoon /> : <FiSun />}
              onClick={toggleColorMode}
              aria-label="Toggle color mode"
              variant="ghost"
              color={iconColor}
              _hover={{ bg: hoverBg }}
            />

            <Box position="relative">
              <IconButton
                icon={<FiBell />}
                aria-label="Notifications"
                variant="ghost"
                color={iconColor}
                onClick={onOpen}
                _hover={{ bg: hoverBg }}
              />
              {unreadCount > 0 && (
                <Badge
                  colorScheme="red"
                  variant="solid"
                  borderRadius="full"
                  position="absolute"
                  top="-1"
                  right="-1"
                  fontSize="xs"
                  minW={4}
                >
                  {unreadCount}
                </Badge>
              )}
            </Box>

            <Menu>
              <MenuButton
                as={Button}
                variant="ghost"
                leftIcon={<Avatar size="sm" name={user.username} />}
                _hover={{ bg: hoverBg }}
                px={2}
              >
                <Text display={{ base: 'none', md: 'block' }}>{user.username}</Text>
              </MenuButton>
              <MenuList bg={menuBg}>
                <MenuItem icon={<FiUser />}>Profile</MenuItem>
                <MenuDivider />
                <MenuItem icon={<FiLogOut />} onClick={logout}>
                  Logout
                </MenuItem>
              </MenuList>
            </Menu>
          </HStack>
        </Flex>
      </Box>
      <NotificationsDrawer isOpen={isOpen} onClose={onClose} />
    </>
  );
};

export default Navbar;
