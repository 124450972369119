import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Input,
  VStack,
  useToast,
  Switch,
  Text,
  IconButton,
  HStack,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ModalCloseButton,
  useDisclosure,
  Textarea,
  Select,
  Badge,
  FormErrorMessage,
  useColorModeValue,
  Card,
  CardBody,
  Heading,
} from '@chakra-ui/react';
import { DeleteIcon, EditIcon } from '@chakra-ui/icons';
import { integrationApi } from '../services/api';

interface Integration {
  id: string;
  type: 'email' | 'telegram' | 'whatsapp' | 'pushover' | 'webhook';
  name: string;
  enabled: boolean;
  config: EmailConfig | TelegramConfig | WhatsAppConfig | PushoverConfig | WebhookConfig;
  created_at: string;
  updated_at: string;
}

interface EmailConfig {
  smtp_server: string;
  smtp_port: number;
  username: string;
  password: string;
  from_email: string;
  recipients: string[];
}

interface TelegramConfig {
  bot_token: string;
  chat_ids: string[];
}

interface WhatsAppConfig {
  account_sid: string;
  auth_token: string;
  from_number: string;
  to_numbers: string[];
}

interface PushoverConfig {
  api_token: string;
  user_key: string;
  priority: number;
}

interface WebhookConfig {
  url: string;
  method: 'POST' | 'GET';
  headers: Record<string, string>;
}

const IntegrationSettings: React.FC = () => {
  const [integrations, setIntegrations] = useState<Integration[]>([]);
  const [selectedIntegration, setSelectedIntegration] = useState<Integration | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [formError, setFormError] = useState<string | null>(null);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const toast = useToast();

  // Color mode values
  const bg = useColorModeValue('white', 'gray.800');
  const borderColor = useColorModeValue('gray.200', 'gray.600');
  const textColor = useColorModeValue('gray.800', 'white');
  const mutedColor = useColorModeValue('gray.600', 'gray.300');

  const getDefaultConfig = (type: Integration['type']) => {
    const defaultConfigs = {
      email: {
        smtp_server: '',
        smtp_port: 587,
        username: '',
        password: '',
        from_email: '',
        recipients: []
      } as EmailConfig,
      telegram: {
        bot_token: '',
        chat_ids: []
      } as TelegramConfig,
      whatsapp: {
        account_sid: '',
        auth_token: '',
        from_number: '',
        to_numbers: []
      } as WhatsAppConfig,
      pushover: {
        api_token: '',
        user_key: '',
        priority: 0
      } as PushoverConfig,
      webhook: {
        url: '',
        method: 'POST',
        headers: {}
      } as WebhookConfig
    };

    return defaultConfigs[type];
  };

  const [newIntegration, setNewIntegration] = useState<Partial<Integration>>({
    type: 'webhook',
    name: '',
    enabled: true,
    config: getDefaultConfig('webhook')
  });

  const resetIntegrationForm = (type: Integration['type'] = 'webhook') => {
    setNewIntegration({
      type,
      name: '',
      enabled: true,
      config: getDefaultConfig(type)
    });
  };

  const handleTypeChange = (type: Integration['type']) => {
    const defaultConfig = getDefaultConfig(type);
    setNewIntegration(prev => ({
      ...prev,
      type,
      config: defaultConfig
    }));
  };

  const fetchIntegrations = async () => {
    setIsLoading(true);
    setError(null);
    try {
      const data = await integrationApi.getIntegrations();
      setIntegrations(data);
    } catch (error: any) {
      const errorMessage = error.response?.data?.detail || 'Failed to fetch integrations';
      setError(errorMessage);
      toast({
        title: 'Error',
        description: errorMessage,
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchIntegrations();
  }, []);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    console.log('Submitting integration form...');
    console.log('Form data:', newIntegration);
    setFormError(null);

    if (!newIntegration.name.trim()) {
      console.warn('Validation error: Name is required');
      setFormError('Name is required');
      return;
    }

    try {
      if (selectedIntegration) {
        console.log('Updating integration:', selectedIntegration.id);
        await integrationApi.updateIntegration(selectedIntegration.id, newIntegration);
        console.log('Integration updated successfully');
      } else {
        console.log('Creating new integration');
        await integrationApi.createIntegration(newIntegration);
        console.log('Integration created successfully');
      }

      toast({
        title: 'Success',
        description: `Integration ${selectedIntegration ? 'updated' : 'created'} successfully`,
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
      onClose();
      fetchIntegrations();
    } catch (error: any) {
      console.group('Error saving integration');
      console.error('Error object:', error);
      console.error('Response:', error.response);
      console.error('Request:', error.config);
      if (error.response) {
        console.error('Status:', error.response.status);
        console.error('Data:', error.response.data);
        console.error('Headers:', error.response.headers);
      }
      console.groupEnd();

      const errorMessage = error.response?.data?.detail || 'Failed to save integration';
      setFormError(errorMessage);
      toast({
        title: 'Error',
        description: errorMessage,
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const handleDelete = async (integrationId: string) => {
    console.log('Deleting integration:', integrationId);
    try {
      await integrationApi.deleteIntegration(integrationId);
      console.log('Integration deleted successfully');
      toast({
        title: 'Success',
        description: 'Integration deleted successfully',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
      fetchIntegrations();
    } catch (error: any) {
      console.group('Error deleting integration');
      console.error('Error object:', error);
      console.error('Response:', error.response);
      console.error('Request:', error.config);
      if (error.response) {
        console.error('Status:', error.response.status);
        console.error('Data:', error.response.data);
        console.error('Headers:', error.response.headers);
      }
      console.groupEnd();

      const errorMessage = error.response?.data?.detail || 'Failed to delete integration';
      toast({
        title: 'Error',
        description: errorMessage,
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const handleEdit = (integration: Integration) => {
    console.log('Editing integration:', integration);
    setSelectedIntegration(integration);
    
    // Get the default config for the integration type and merge with existing config
    const defaultConfig = getDefaultConfig(integration.type);
    const mergedConfig = { ...defaultConfig, ...integration.config };
    
    setNewIntegration({
      type: integration.type,
      name: integration.name,
      enabled: integration.enabled,
      config: mergedConfig,
    });
    setFormError(null);
    onOpen();
  };

  const handleToggle = async (integration: Integration) => {
    try {
      await integrationApi.updateIntegration(integration.id, { ...integration, enabled: !integration.enabled });
      fetchIntegrations();
    } catch (error: any) {
      console.group('Error toggling integration');
      console.error('Error object:', error);
      console.error('Response:', error.response);
      console.error('Request:', error.config);
      if (error.response) {
        console.error('Status:', error.response.status);
        console.error('Data:', error.response.data);
        console.error('Headers:', error.response.headers);
      }
      console.groupEnd();

      const errorMessage = error.response?.data?.detail || 'Failed to toggle integration';
      toast({
        title: 'Error',
        description: errorMessage,
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  return (
    <Box>
      <Button 
        colorScheme="blue" 
        mb={6} 
        onClick={() => {
          setSelectedIntegration(null);
          resetIntegrationForm();
          onOpen();
        }}
      >
        Add Integration
      </Button>

      <VStack spacing={4} align="stretch">
        {integrations.map((integration) => (
          <Box
            key={integration.id}
            p={4}
            borderWidth="1px"
            borderRadius="lg"
            position="relative"
          >
            <HStack justify="space-between" mb={2}>
              <Text fontWeight="bold">{integration.name}</Text>
              <HStack>
                <Switch
                  isChecked={integration.enabled}
                  onChange={() => handleToggle(integration)}
                  colorScheme="green"
                />
                <IconButton
                  aria-label="Edit integration"
                  icon={<EditIcon />}
                  size="sm"
                  onClick={() => handleEdit(integration)}
                />
                <IconButton
                  aria-label="Delete integration"
                  icon={<DeleteIcon />}
                  size="sm"
                  colorScheme="red"
                  onClick={() => handleDelete(integration.id)}
                />
              </HStack>
            </HStack>
            <Text color="gray.600" mb={2}>{integration.type}</Text>
            <Text fontSize="sm" mb={2}>Config: {JSON.stringify(integration.config)}</Text>
          </Box>
        ))}
      </VStack>

      <Modal isOpen={isOpen} onClose={onClose} size="xl">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            {selectedIntegration?.id ? 'Edit Integration' : 'Add Integration'}
          </ModalHeader>
          <ModalCloseButton />
          <form onSubmit={handleSubmit}>
            <ModalBody>
              <VStack spacing={4}>
                <FormControl isRequired>
                  <FormLabel>Type</FormLabel>
                  <Select
                    value={newIntegration.type}
                    onChange={(e) => handleTypeChange(e.target.value as Integration['type'])}
                  >
                    <option value="email">Email</option>
                    <option value="telegram">Telegram</option>
                    <option value="whatsapp">WhatsApp</option>
                    <option value="pushover">Pushover</option>
                    <option value="webhook">Webhook</option>
                  </Select>
                </FormControl>

                <FormControl isRequired>
                  <FormLabel>Name</FormLabel>
                  <Input
                    value={newIntegration.name}
                    onChange={(e) =>
                      setNewIntegration(prev => ({ ...prev, name: e.target.value }))
                    }
                  />
                  {formError && <FormErrorMessage>{formError}</FormErrorMessage>}
                </FormControl>

                <FormControl>
                  <FormLabel>Enabled</FormLabel>
                  <Switch
                    isChecked={newIntegration.enabled}
                    onChange={(e) =>
                      setNewIntegration(prev => ({ ...prev, enabled: e.target.checked }))
                    }
                  />
                </FormControl>

                {newIntegration.type === 'email' && (
                  <VStack spacing={4}>
                    <FormControl>
                      <FormLabel>SMTP Server</FormLabel>
                      <Input
                        value={(newIntegration.config as EmailConfig).smtp_server}
                        onChange={(e) =>
                          setNewIntegration(prev => ({ ...prev, config: { ...prev.config, smtp_server: e.target.value } as EmailConfig }))
                        }
                      />
                    </FormControl>

                    <FormControl>
                      <FormLabel>SMTP Port</FormLabel>
                      <Input
                        type="number"
                        value={(newIntegration.config as EmailConfig).smtp_port}
                        onChange={(e) =>
                          setNewIntegration(prev => ({ ...prev, config: { ...prev.config, smtp_port: parseInt(e.target.value) } as EmailConfig }))
                        }
                      />
                    </FormControl>

                    <FormControl>
                      <FormLabel>Username</FormLabel>
                      <Input
                        value={(newIntegration.config as EmailConfig).username}
                        onChange={(e) =>
                          setNewIntegration(prev => ({ ...prev, config: { ...prev.config, username: e.target.value } as EmailConfig }))
                        }
                      />
                    </FormControl>

                    <FormControl>
                      <FormLabel>Password</FormLabel>
                      <Input
                        type="password"
                        value={(newIntegration.config as EmailConfig).password}
                        onChange={(e) =>
                          setNewIntegration(prev => ({ ...prev, config: { ...prev.config, password: e.target.value } as EmailConfig }))
                        }
                      />
                    </FormControl>

                    <FormControl>
                      <FormLabel>From Email</FormLabel>
                      <Input
                        value={(newIntegration.config as EmailConfig).from_email}
                        onChange={(e) =>
                          setNewIntegration(prev => ({ ...prev, config: { ...prev.config, from_email: e.target.value } as EmailConfig }))
                        }
                      />
                    </FormControl>

                    <FormControl>
                      <FormLabel>Recipients</FormLabel>
                      <Input
                        value={(newIntegration.config as EmailConfig).recipients.join(', ')}
                        onChange={(e) =>
                          setNewIntegration(prev => ({ ...prev, config: { ...prev.config, recipients: e.target.value.split(', ') } as EmailConfig }))
                        }
                      />
                    </FormControl>
                  </VStack>
                )}

                {newIntegration.type === 'telegram' && (
                  <VStack spacing={4}>
                    <FormControl>
                      <FormLabel>Bot Token</FormLabel>
                      <Input
                        value={(newIntegration.config as TelegramConfig).bot_token}
                        onChange={(e) =>
                          setNewIntegration(prev => ({ ...prev, config: { ...prev.config, bot_token: e.target.value } as TelegramConfig }))
                        }
                      />
                    </FormControl>

                    <FormControl>
                      <FormLabel>Chat IDs</FormLabel>
                      <Input
                        value={(newIntegration.config as TelegramConfig).chat_ids.join(', ')}
                        onChange={(e) =>
                          setNewIntegration(prev => ({ ...prev, config: { ...prev.config, chat_ids: e.target.value.split(', ') } as TelegramConfig }))
                        }
                      />
                    </FormControl>
                  </VStack>
                )}

                {newIntegration.type === 'whatsapp' && (
                  <VStack spacing={4}>
                    <FormControl>
                      <FormLabel>Account SID</FormLabel>
                      <Input
                        value={(newIntegration.config as WhatsAppConfig).account_sid}
                        onChange={(e) =>
                          setNewIntegration(prev => ({ ...prev, config: { ...prev.config, account_sid: e.target.value } as WhatsAppConfig }))
                        }
                      />
                    </FormControl>

                    <FormControl>
                      <FormLabel>Auth Token</FormLabel>
                      <Input
                        type="password"
                        value={(newIntegration.config as WhatsAppConfig).auth_token}
                        onChange={(e) =>
                          setNewIntegration(prev => ({ ...prev, config: { ...prev.config, auth_token: e.target.value } as WhatsAppConfig }))
                        }
                      />
                    </FormControl>

                    <FormControl>
                      <FormLabel>From Number</FormLabel>
                      <Input
                        value={(newIntegration.config as WhatsAppConfig).from_number}
                        onChange={(e) =>
                          setNewIntegration(prev => ({ ...prev, config: { ...prev.config, from_number: e.target.value } as WhatsAppConfig }))
                        }
                      />
                    </FormControl>

                    <FormControl>
                      <FormLabel>To Numbers</FormLabel>
                      <Input
                        value={(newIntegration.config as WhatsAppConfig).to_numbers.join(', ')}
                        onChange={(e) =>
                          setNewIntegration(prev => ({ ...prev, config: { ...prev.config, to_numbers: e.target.value.split(', ') } as WhatsAppConfig }))
                        }
                      />
                    </FormControl>
                  </VStack>
                )}

                {newIntegration.type === 'pushover' && (
                  <VStack spacing={4}>
                    <FormControl>
                      <FormLabel>API Token</FormLabel>
                      <Input
                        value={(newIntegration.config as PushoverConfig).api_token}
                        onChange={(e) =>
                          setNewIntegration(prev => ({ ...prev, config: { ...prev.config, api_token: e.target.value } as PushoverConfig }))
                        }
                      />
                    </FormControl>

                    <FormControl>
                      <FormLabel>User Key</FormLabel>
                      <Input
                        value={(newIntegration.config as PushoverConfig).user_key}
                        onChange={(e) =>
                          setNewIntegration(prev => ({ ...prev, config: { ...prev.config, user_key: e.target.value } as PushoverConfig }))
                        }
                      />
                    </FormControl>

                    <FormControl>
                      <FormLabel>Priority</FormLabel>
                      <Input
                        type="number"
                        value={(newIntegration.config as PushoverConfig).priority}
                        onChange={(e) =>
                          setNewIntegration(prev => ({ ...prev, config: { ...prev.config, priority: parseInt(e.target.value) } as PushoverConfig }))
                        }
                      />
                    </FormControl>
                  </VStack>
                )}

                {newIntegration.type === 'webhook' && (
                  <VStack spacing={4}>
                    <FormControl>
                      <FormLabel>URL</FormLabel>
                      <Input
                        value={(newIntegration.config as WebhookConfig).url}
                        onChange={(e) =>
                          setNewIntegration(prev => ({ ...prev, config: { ...prev.config, url: e.target.value } as WebhookConfig }))
                        }
                      />
                    </FormControl>

                    <FormControl>
                      <FormLabel>Method</FormLabel>
                      <Select
                        value={(newIntegration.config as WebhookConfig).method}
                        onChange={(e) =>
                          setNewIntegration(prev => ({ ...prev, config: { ...prev.config, method: e.target.value as 'POST' | 'GET' } as WebhookConfig }))
                        }
                      >
                        <option value="POST">POST</option>
                        <option value="GET">GET</option>
                      </Select>
                    </FormControl>

                    <FormControl>
                      <FormLabel>Headers</FormLabel>
                      <Textarea
                        value={JSON.stringify((newIntegration.config as WebhookConfig).headers, null, 2)}
                        onChange={(e) => {
                          try {
                            const headers = JSON.parse(e.target.value);
                            setNewIntegration(prev => ({ ...prev, config: { ...prev.config, headers } as WebhookConfig }));
                          } catch (error) {
                            // Invalid JSON, ignore
                          }
                        }}
                        fontFamily="mono"
                      />
                    </FormControl>
                  </VStack>
                )}
              </VStack>
            </ModalBody>
            <ModalFooter>
              <Button variant="ghost" mr={3} onClick={onClose}>
                Cancel
              </Button>
              <Button colorScheme="blue" type="submit">
                Save
              </Button>
            </ModalFooter>
          </form>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default IntegrationSettings;
