import React, { createContext, useContext, useState } from 'react';

interface ValidationCriteriaContextType {
  criteria: string[];
  setCriteria: React.Dispatch<React.SetStateAction<string[]>>;
}

const ValidationCriteriaContext = createContext<ValidationCriteriaContextType | undefined>(undefined);

export const useValidationCriteria = () => {
  const context = useContext(ValidationCriteriaContext);
  if (!context) {
    throw new Error('useValidationCriteria must be used within a ValidationCriteriaProvider');
  }
  return context;
};

export const ValidationCriteriaProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [criteria, setCriteria] = useState<string[]>([]);

  return (
    <ValidationCriteriaContext.Provider value={{ criteria, setCriteria }}>
      {children}
    </ValidationCriteriaContext.Provider>
  );
};
