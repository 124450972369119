import axios from 'axios';
import { Site, SiteResponse } from '../types/site';

const API_URL = (process.env.REACT_APP_API_URL || 'https://vidiogpt.com/api').replace(/^http:/, 'https:');

// Helper function to get auth token
const getAuthHeader = () => {
  const token = localStorage.getItem('token');
  return token ? { Authorization: `Bearer ${token}` } : {};
};

export const siteService = {
  getSites: async (): Promise<SiteResponse> => {
    const response = await axios.get(`${API_URL}/v1/sites`, {
      headers: getAuthHeader()
    });
    if (!response.data.sites) {
      // If the response doesn't match our expected format, transform it
      return {
        sites: Array.isArray(response.data) ? response.data : [],
        total: Array.isArray(response.data) ? response.data.length : 0
      };
    }
    return response.data;
  },

  getSite: async (siteId: string): Promise<Site> => {
    const response = await axios.get(`${API_URL}/v1/sites/${siteId}`, {
      headers: getAuthHeader()
    });
    return response.data;
  },

  createSite: async (site: Omit<Site, 'siteId' | 'createdAt' | 'updatedAt'>): Promise<Site> => {
    const response = await axios.post(`${API_URL}/v1/sites`, site, {
      headers: getAuthHeader()
    });
    return response.data;
  },

  updateSite: async (siteId: string, site: Partial<Site>): Promise<Site> => {
    const response = await axios.put(`${API_URL}/v1/sites/${siteId}`, site, {
      headers: getAuthHeader()
    });
    return response.data;
  },

  deleteSite: async (siteId: string): Promise<void> => {
    await axios.delete(`${API_URL}/v1/sites/${siteId}`, {
      headers: getAuthHeader()
    });
  }
};
