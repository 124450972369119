import React from 'react';
import {
  Box,
  Container,
} from '@chakra-ui/react';
import SiteList from '../components/sites/SiteList';

const MonitoringPage: React.FC = () => {
  return (
    <Container maxW="container.xl" py={5}>
      <Box>
        <SiteList />
      </Box>
    </Container>
  );
};

export default MonitoringPage;
