import axios from 'axios';
import { Group, GroupResponse } from '../types/group';

const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:3000/api';

// Helper function to get auth token
const getAuthHeader = () => {
  const token = localStorage.getItem('token');
  return token ? { Authorization: `Bearer ${token}` } : {};
};

export const groupService = {
  getGroups: async (): Promise<GroupResponse> => {
    const response = await axios.get(`${API_URL}/groups`, {
      headers: getAuthHeader()
    });
    if (!response.data.groups) {
      // If the response doesn't match our expected format, transform it
      return {
        groups: Array.isArray(response.data) ? response.data : [],
        total: Array.isArray(response.data) ? response.data.length : 0
      };
    }
    return response.data;
  },

  getGroup: async (groupId: string): Promise<Group> => {
    const response = await axios.get(`${API_URL}/groups/${groupId}`, {
      headers: getAuthHeader()
    });
    return response.data;
  },

  getChannelGroups: async (channelId: string): Promise<GroupResponse> => {
    const response = await axios.get(`${API_URL}/channels/${channelId}/groups`, {
      headers: getAuthHeader()
    });
    if (!response.data.groups) {
      return {
        groups: Array.isArray(response.data) ? response.data : [],
        total: Array.isArray(response.data) ? response.data.length : 0
      };
    }
    return response.data;
  },

  getGroupChannels: async (groupId: string): Promise<{ channels: any[] }> => {
    const response = await axios.get(`${API_URL}/groups/${groupId}/channels`, {
      headers: getAuthHeader()
    });
    return response.data;
  },

  createGroup: async (group: Omit<Group, 'group_id' | 'created_at' | 'updated_at' | 'channels'>): Promise<Group> => {
    const response = await axios.post(`${API_URL}/groups`, group, {
      headers: getAuthHeader()
    });
    return response.data;
  },

  updateGroup: async (groupId: string, group: Partial<Group>): Promise<Group> => {
    const response = await axios.put(`${API_URL}/groups/${groupId}`, group, {
      headers: getAuthHeader()
    });
    return response.data;
  },

  deleteGroup: async (groupId: string): Promise<void> => {
    await axios.delete(`${API_URL}/groups/${groupId}`, {
      headers: getAuthHeader()
    });
  },

  addChannelToGroup: async (groupId: string, channelId: string): Promise<void> => {
    await axios.post(`${API_URL}/groups/${groupId}/channels/${channelId}`, {}, {
      headers: getAuthHeader()
    });
  },

  removeChannelFromGroup: async (groupId: string, channelId: string): Promise<void> => {
    await axios.delete(`${API_URL}/groups/${groupId}/channels/${channelId}`, {
      headers: getAuthHeader()
    });
  }
};
