import axios from 'axios';
import { Channel, ChannelResponse } from '../types/channel';

const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:3000/api';

// Helper function to get auth token
const getAuthHeader = () => {
  const token = localStorage.getItem('token');
  return token ? { Authorization: `Bearer ${token}` } : {};
};

export const channelService = {
  getChannels: async (siteId?: string): Promise<ChannelResponse> => {
    const url = siteId 
      ? `${API_URL}/channels?siteId=${siteId}`
      : `${API_URL}/channels`;
    const response = await axios.get(url, {
      headers: getAuthHeader()
    });
    if (!response.data.channels) {
      // If the response doesn't match our expected format, transform it
      return {
        channels: Array.isArray(response.data) ? response.data : [],
        total: Array.isArray(response.data) ? response.data.length : 0
      };
    }
    return response.data;
  },

  getChannel: async (channelId: string): Promise<Channel> => {
    const response = await axios.get(`${API_URL}/channels/${channelId}`, {
      headers: getAuthHeader()
    });
    return response.data;
  },

  createChannel: async (channel: Omit<Channel, 'channelId' | 'createdAt' | 'updatedAt'>): Promise<Channel> => {
    const response = await axios.post(`${API_URL}/channels`, channel, {
      headers: getAuthHeader()
    });
    return response.data;
  },

  updateChannel: async (channelId: string, channel: Partial<Channel>): Promise<Channel> => {
    const response = await axios.put(`${API_URL}/channels/${channelId}`, channel, {
      headers: getAuthHeader()
    });
    return response.data;
  },

  deleteChannel: async (channelId: string): Promise<void> => {
    await axios.delete(`${API_URL}/channels/${channelId}`, {
      headers: getAuthHeader()
    });
  },

  updateChannelStatus: async (channelId: string, status: Channel['status']): Promise<Channel> => {
    const response = await axios.patch(`${API_URL}/channels/${channelId}/status`, { status }, {
      headers: getAuthHeader()
    });
    return response.data;
  }
};
