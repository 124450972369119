import React, { useEffect, useState } from 'react';
import {
  Box,
  Container,
  Heading,
  VStack,
  Button,
  FormControl,
  FormLabel,
  Input,
  Select,
  useToast,
  Text,
  Switch,
  HStack,
  Divider,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
} from '@chakra-ui/react';
import { casesApi } from '../services/api';

interface ValidationCriteria {
  id: number;
  name: string;
  description: string;
  field: string;
  operator: string;
  value: number;
  is_active: boolean;
  created_at: string;
}

const Settings: React.FC = () => {
  const [criteria, setCriteria] = useState<ValidationCriteria[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const toast = useToast();

  const [newCriteria, setNewCriteria] = useState({
    name: '',
    description: '',
    field: 'people_count',
    operator: '>',
    value: 0,
  });

  const fields = [
    { value: 'people_count', label: 'People Count' },
    { value: 'real_person_count', label: 'Real Person Count' },
    { value: 'person_sitting', label: 'Person Sitting' },
    { value: 'person_lying_down', label: 'Person Lying Down' },
    { value: 'helmet_count', label: 'Helmet Count' },
    { value: 'face_mask_count', label: 'Face Mask Count' },
    { value: 'vehicle_count', label: 'Vehicle Count' },
    { value: 'face_count', label: 'Face Count' },
    { value: 'atm_count', label: 'ATM Count' },
    { value: 'bag_count', label: 'Bag Count' },
    { value: 'weapon', label: 'Weapon Present' },
    { value: 'threat', label: 'Threat Present' },
    { value: 'dustbin_count', label: 'Dustbin Count' },
  ];

  const operators = [
    { value: '>', label: 'Greater Than' },
    { value: '<', label: 'Less Than' },
    { value: '=', label: 'Equal To' },
    { value: '!=', label: 'Not Equal To' },
    { value: '>=', label: 'Greater Than or Equal To' },
    { value: '<=', label: 'Less Than or Equal To' },
  ];

  useEffect(() => {
    fetchCriteria();
  }, []);

  const fetchCriteria = async () => {
    try {
      setIsLoading(true);
      const response = await casesApi.getValidationCriteria();
      setCriteria(response);
    } catch (error) {
      console.error('Error fetching validation criteria:', error);
      toast({
        title: 'Error',
        description: 'Failed to fetch validation criteria',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleCreateCriteria = async () => {
    try {
      await casesApi.createValidationCriteria(newCriteria);
      toast({
        title: 'Success',
        description: 'Validation criteria created successfully',
        status: 'success',
        duration: 5000,
        isClosable: true,
      });
      fetchCriteria();
      onClose();
      setNewCriteria({
        name: '',
        description: '',
        field: 'people_count',
        operator: '>',
        value: 0,
      });
    } catch (error) {
      console.error('Error creating validation criteria:', error);
      toast({
        title: 'Error',
        description: 'Failed to create validation criteria',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const handleToggleCriteria = async (criteriaId: number) => {
    try {
      await casesApi.toggleValidationCriteria(criteriaId);
      fetchCriteria();
    } catch (error) {
      console.error('Error toggling validation criteria:', error);
      toast({
        title: 'Error',
        description: 'Failed to toggle validation criteria',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  return (
    <Container maxW="container.lg" py={8}>
      <Box mb={8}>
        <Heading mb={4}>Settings</Heading>
        <Text color="gray.600">
          Configure validation criteria for ATM surveillance cases.
        </Text>
      </Box>

      <Button colorScheme="blue" onClick={onOpen} mb={8}>
        Add New Validation Criteria
      </Button>

      <VStack spacing={4} align="stretch">
        <Heading size="md" mb={2}>
          Validation Criteria
        </Heading>
        {criteria.map((criterion) => (
          <Box
            key={criterion.id}
            p={4}
            borderWidth="1px"
            borderRadius="lg"
            shadow="sm"
          >
            <HStack justify="space-between">
              <VStack align="start" spacing={1}>
                <Heading size="sm">{criterion.name}</Heading>
                <Text color="gray.600" fontSize="sm">
                  {criterion.description}
                </Text>
                <Text fontSize="sm">
                  {fields.find((f) => f.value === criterion.field)?.label} {' '}
                  {operators.find((o) => o.value === criterion.operator)?.label} {' '}
                  {criterion.value}
                </Text>
              </VStack>
              <Switch
                isChecked={criterion.is_active}
                onChange={() => handleToggleCriteria(criterion.id)}
                colorScheme="green"
              />
            </HStack>
          </Box>
        ))}
      </VStack>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Add New Validation Criteria</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <VStack spacing={4}>
              <FormControl>
                <FormLabel>Name</FormLabel>
                <Input
                  value={newCriteria.name}
                  onChange={(e) =>
                    setNewCriteria({ ...newCriteria, name: e.target.value })
                  }
                  placeholder="Enter criteria name"
                />
              </FormControl>

              <FormControl>
                <FormLabel>Description</FormLabel>
                <Input
                  value={newCriteria.description}
                  onChange={(e) =>
                    setNewCriteria({ ...newCriteria, description: e.target.value })
                  }
                  placeholder="Enter criteria description"
                />
              </FormControl>

              <FormControl>
                <FormLabel>Field</FormLabel>
                <Select
                  value={newCriteria.field}
                  onChange={(e) =>
                    setNewCriteria({ ...newCriteria, field: e.target.value })
                  }
                >
                  {fields.map((field) => (
                    <option key={field.value} value={field.value}>
                      {field.label}
                    </option>
                  ))}
                </Select>
              </FormControl>

              <FormControl>
                <FormLabel>Operator</FormLabel>
                <Select
                  value={newCriteria.operator}
                  onChange={(e) =>
                    setNewCriteria({ ...newCriteria, operator: e.target.value })
                  }
                >
                  {operators.map((op) => (
                    <option key={op.value} value={op.value}>
                      {op.label}
                    </option>
                  ))}
                </Select>
              </FormControl>

              <FormControl>
                <FormLabel>Value</FormLabel>
                <Input
                  type="number"
                  value={newCriteria.value}
                  onChange={(e) =>
                    setNewCriteria({
                      ...newCriteria,
                      value: parseFloat(e.target.value),
                    })
                  }
                  placeholder="Enter threshold value"
                />
              </FormControl>
            </VStack>
          </ModalBody>
          <ModalFooter>
            <Button variant="ghost" mr={3} onClick={onClose}>
              Cancel
            </Button>
            <Button colorScheme="blue" onClick={handleCreateCriteria}>
              Create
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Container>
  );
};

export default Settings;
