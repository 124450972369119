import React, { useEffect, useState } from 'react';
import {
  Box,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Badge,
  Heading,
  Button,
  HStack,
  useColorModeValue,
  Text,
  VStack,
  IconButton,
  useToast,
  Checkbox,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Select,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  FormControl,
  FormLabel,
  Input,
  Tag,
  TagLabel,
  TagCloseButton,
  Wrap,
  WrapItem,
  Stack,
} from '@chakra-ui/react';
import { FiMapPin, FiAlertCircle, FiEdit2, FiTrash2, FiCamera, FiUsers, FiChevronDown } from 'react-icons/fi';
import { channelService } from '../services/channelService';
import { groupService } from '../services/groupService';
import { Group } from '../types/group';

interface Site {
  siteId: string;
  locationName: string;
  address: string;
}

interface Channel {
  channelId: string;
  cameraName: string;
  siteId: string;
  status: 'operational' | 'maintenance' | 'offline';
  totalIncidents: number;
  openIncidents: number;
  createdAt: string;
  updatedAt: string;
  site: Site;
  groups?: Group[];
}

const StatusBadge = ({ status }: { status: Channel['status'] }) => {
  const getColorScheme = () => {
    switch (status) {
      case 'operational':
        return 'green';
      case 'maintenance':
        return 'yellow';
      case 'offline':
        return 'red';
      default:
        return 'gray';
    }
  };

  const getLabel = () => {
    switch (status) {
      case 'operational':
        return 'Online';
      case 'maintenance':
        return 'Maintenance';
      case 'offline':
        return 'Offline';
      default:
        return 'Unknown';
    }
  };

  return (
    <Badge colorScheme={getColorScheme()}>
      {getLabel()}
    </Badge>
  );
};

const Cameras: React.FC = () => {
  const [cameras, setCameras] = useState<Channel[]>([]);
  const [groups, setGroups] = useState<Group[]>([]);
  const [selectedCameras, setSelectedCameras] = useState<string[]>([]);
  const [selectedGroup, setSelectedGroup] = useState<string>('');
  const [loading, setLoading] = useState(true);
  const [newGroupName, setNewGroupName] = useState('');
  const [isCreateGroupModalOpen, setIsCreateGroupModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [selectedCamera, setSelectedCamera] = useState<Channel | null>(null);
  const [editFormData, setEditFormData] = useState<Partial<Channel>>({
    cameraName: '',
    status: 'operational'
  });
  const [cameraGroups, setCameraGroups] = useState<Group[]>([]);
  const [availableGroups, setAvailableGroups] = useState<Group[]>([]);
  const toast = useToast();
  const bg = useColorModeValue('white', 'gray.800');

  useEffect(() => {
    loadCameras();
    loadGroups();
  }, []);

  useEffect(() => {
    if (selectedGroup) {
      loadCamerasByGroup(selectedGroup);
    } else {
      loadCameras();
    }
  }, [selectedGroup]);

  const loadGroups = async () => {
    try {
      const response = await groupService.getGroups();
      setGroups(response.groups);
    } catch (error) {
      console.error('Error loading groups:', error);
      toast({
        title: 'Error loading groups',
        description: 'There was an error loading the groups.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const loadCamerasByGroup = async (groupId: string) => {
    try {
      setLoading(true);
      const response = await groupService.getGroupChannels(groupId);
      setCameras(response.channels || []);
    } catch (error) {
      console.error('Error loading cameras by group:', error);
      toast({
        title: 'Error loading cameras',
        description: 'There was an error loading the cameras for this group.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setLoading(false);
    }
  };

  const loadCameras = async () => {
    try {
      setLoading(true);
      const response = await channelService.getChannels();
      const camerasWithGroups = await Promise.all(
        response.channels.map(async (camera) => {
          try {
            const groupsResponse = await groupService.getChannelGroups(camera.channelId);
            return {
              ...camera,
              groups: groupsResponse.groups
            };
          } catch (error) {
            console.error(`Error loading groups for camera ${camera.channelId}:`, error);
            return {
              ...camera,
              groups: []
            };
          }
        })
      );
      setCameras(camerasWithGroups);
    } catch (error) {
      toast({
        title: 'Error loading cameras',
        description: 'There was an error loading the camera list.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
      console.error('Error loading cameras:', error);
    } finally {
      setLoading(false);
    }
  };

  const loadCameraGroups = async (channelId: string) => {
    try {
      const response = await groupService.getChannelGroups(channelId);
      setCameraGroups(response.groups || []);
      // Filter out groups that the camera is already part of
      const available = groups.filter(group => 
        !response.groups.some(cg => cg.group_id === group.group_id)
      );
      setAvailableGroups(available);
    } catch (error) {
      console.error('Error loading camera groups:', error);
      toast({
        title: 'Error loading groups',
        description: 'There was an error loading the camera groups.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const handleEdit = async (channelId: string) => {
    const camera = cameras.find(c => c.channelId === channelId);
    if (camera) {
      setSelectedCamera(camera);
      setEditFormData({
        cameraName: camera.cameraName,
        status: camera.status
      });
      await loadCameraGroups(channelId);
      setIsEditModalOpen(true);
    }
  };

  const handleAddToGroup = async (groupId: string) => {
    if (!selectedCamera) return;
    try {
      await groupService.addChannelToGroup(groupId, selectedCamera.channelId);
      await loadCameraGroups(selectedCamera.channelId);
      toast({
        title: 'Added to group',
        description: 'Camera has been added to the group.',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      console.error('Error adding to group:', error);
      toast({
        title: 'Error',
        description: 'Failed to add camera to group.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const handleRemoveFromGroup = async (groupId: string) => {
    if (!selectedCamera) return;
    try {
      await groupService.removeChannelFromGroup(groupId, selectedCamera.channelId);
      await loadCameraGroups(selectedCamera.channelId);
      toast({
        title: 'Removed from group',
        description: 'Camera has been removed from the group.',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      console.error('Error removing from group:', error);
      toast({
        title: 'Error',
        description: 'Failed to remove camera from group.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const handleEditSubmit = async () => {
    if (!selectedCamera || !editFormData.cameraName) return;

    try {
      await channelService.updateChannel(selectedCamera.channelId, editFormData);
      toast({
        title: 'Camera updated',
        description: 'The camera has been successfully updated.',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
      loadCameras(); // Reload the list
      setIsEditModalOpen(false);
    } catch (error) {
      toast({
        title: 'Error updating camera',
        description: 'There was an error updating the camera.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
      console.error('Error updating camera:', error);
    }
  };

  const handleEditChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = e.target;
    setEditFormData(prev => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleDelete = async (channelId: string) => {
    try {
      await channelService.deleteChannel(channelId);
      toast({
        title: 'Camera deleted',
        description: 'The camera has been successfully deleted.',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
      loadCameras(); // Reload the list
    } catch (error) {
      toast({
        title: 'Error deleting camera',
        description: 'There was an error deleting the camera.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
      console.error('Error deleting camera:', error);
    }
  };

  const handleSelectCamera = (channelId: string) => {
    setSelectedCameras(prev => 
      prev.includes(channelId)
        ? prev.filter(id => id !== channelId)
        : [...prev, channelId]
    );
  };

  const handleSelectAll = () => {
    setSelectedCameras(
      selectedCameras.length === cameras.length
        ? []
        : cameras.map(camera => camera.channelId)
    );
  };

  const handleCreateGroup = async () => {
    if (selectedCameras.length === 0) {
      toast({
        title: 'No cameras selected',
        description: 'Please select at least one camera to create a group.',
        status: 'warning',
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    if (!newGroupName.trim()) {
      toast({
        title: 'Group name required',
        description: 'Please enter a name for the group.',
        status: 'warning',
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    try {
      const newGroup = await groupService.createGroup({
        name: newGroupName,
        description: `Group created from ${selectedCameras.length} cameras`,
        channels: selectedCameras,
      });

      toast({
        title: 'Group created',
        description: `Successfully created group "${newGroupName}"`,
        status: 'success',
        duration: 3000,
        isClosable: true,
      });

      loadGroups();
      setSelectedCameras([]);
      setNewGroupName('');
      setIsCreateGroupModalOpen(false);
    } catch (error) {
      console.error('Error creating group:', error);
      toast({
        title: 'Error creating group',
        description: 'There was an error creating the group.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const handleOpenCreateGroupModal = () => {
    if (selectedCameras.length === 0) {
      toast({
        title: 'No cameras selected',
        description: 'Please select at least one camera to create a group.',
        status: 'warning',
        duration: 3000,
        isClosable: true,
      });
      return;
    }
    setIsCreateGroupModalOpen(true);
  };

  return (
    <Box p={4}>
      <VStack spacing={4} align="stretch">
        <HStack justify="space-between">
          <Heading size="lg">Camera Management</Heading>
          <HStack spacing={2}>
            <Select
              placeholder="Filter by Group"
              value={selectedGroup}
              onChange={(e) => setSelectedGroup(e.target.value)}
              maxW="200px"
            >
              {groups.map((group) => (
                <option key={group.group_id} value={group.group_id}>
                  {group.name}
                </option>
              ))}
            </Select>
            <Button
              colorScheme="blue"
              onClick={handleOpenCreateGroupModal}
              isDisabled={selectedCameras.length === 0}
            >
              Create Group
            </Button>
          </HStack>
        </HStack>

        <Box bg={bg} rounded="lg" shadow="md" overflowX="auto">
          <Table variant="simple">
            <Thead>
              <Tr>
                <Th>
                  <Checkbox
                    isChecked={selectedCameras.length === cameras.length && cameras.length > 0}
                    isIndeterminate={selectedCameras.length > 0 && selectedCameras.length < cameras.length}
                    onChange={handleSelectAll}
                  />
                </Th>
                <Th>Camera Name</Th>
                <Th>Location</Th>
                <Th>Status</Th>
                <Th>Incidents</Th>
                <Th>Actions</Th>
              </Tr>
            </Thead>
            <Tbody>
              {cameras.map((camera) => (
                <Tr key={camera.channelId}>
                  <Td>
                    <Checkbox
                      isChecked={selectedCameras.includes(camera.channelId)}
                      onChange={() => handleSelectCamera(camera.channelId)}
                    />
                  </Td>
                  <Td>
                    <VStack align="start" spacing={2}>
                      <Text>{camera.cameraName}</Text>
                      <Wrap spacing={2}>
                        {camera.groups?.map((group) => (
                          <WrapItem key={group.group_id}>
                            <Tag size="sm" colorScheme="blue" borderRadius="full">
                              <TagLabel>{group.name}</TagLabel>
                            </Tag>
                          </WrapItem>
                        ))}
                      </Wrap>
                    </VStack>
                  </Td>
                  <Td>
                    <HStack>
                      <FiMapPin />
                      <VStack align="start" spacing={0}>
                        <Text fontWeight="medium">{camera.site.locationName}</Text>
                        <Text fontSize="sm" color="gray.500">
                          {camera.site.address}
                        </Text>
                      </VStack>
                    </HStack>
                  </Td>
                  <Td>
                    <StatusBadge status={camera.status} />
                  </Td>
                  <Td>
                    <HStack>
                      <FiAlertCircle />
                      <Text>
                        {camera.openIncidents} open / {camera.totalIncidents} total
                      </Text>
                    </HStack>
                  </Td>
                  <Td>
                    <HStack spacing={2}>
                      <IconButton
                        aria-label="Edit camera"
                        icon={<FiEdit2 />}
                        size="sm"
                        onClick={() => handleEdit(camera.channelId)}
                      />
                      <IconButton
                        aria-label="Delete camera"
                        icon={<FiTrash2 />}
                        size="sm"
                        colorScheme="red"
                        onClick={() => handleDelete(camera.channelId)}
                      />
                    </HStack>
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </Box>
      </VStack>
      <Modal isOpen={isCreateGroupModalOpen} onClose={() => setIsCreateGroupModalOpen(false)}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Create Camera Group</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <VStack spacing={4}>
              <FormControl isRequired>
                <FormLabel>Group Name</FormLabel>
                <Input
                  placeholder="Enter group name"
                  value={newGroupName}
                  onChange={(e) => setNewGroupName(e.target.value)}
                />
              </FormControl>
              <Text>Selected Cameras: {selectedCameras.length}</Text>
            </VStack>
          </ModalBody>
          <ModalFooter>
            <Button variant="ghost" mr={3} onClick={() => setIsCreateGroupModalOpen(false)}>
              Cancel
            </Button>
            <Button colorScheme="blue" onClick={handleCreateGroup}>
              Create Group
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      {/* Edit Camera Modal */}
      <Modal isOpen={isEditModalOpen} onClose={() => setIsEditModalOpen(false)} size="xl">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Edit Camera</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <VStack spacing={6} align="stretch">
              <Stack spacing={4}>
                <FormControl isRequired>
                  <FormLabel>Camera Name</FormLabel>
                  <Input
                    name="cameraName"
                    placeholder="Enter camera name"
                    value={editFormData.cameraName}
                    onChange={handleEditChange}
                  />
                </FormControl>
                <FormControl>
                  <FormLabel>Status</FormLabel>
                  <Select
                    name="status"
                    value={editFormData.status}
                    onChange={handleEditChange}
                  >
                    <option value="operational">Operational</option>
                    <option value="maintenance">Maintenance</option>
                    <option value="offline">Offline</option>
                  </Select>
                </FormControl>
              </Stack>

              <Box>
                <FormLabel>Groups</FormLabel>
                <VStack align="stretch" spacing={4}>
                  <Box>
                    <Text fontSize="sm" fontWeight="medium" mb={2}>
                      Current Groups
                    </Text>
                    <Wrap spacing={2}>
                      {cameraGroups.map((group) => (
                        <WrapItem key={group.group_id}>
                          <Tag size="md" colorScheme="blue" borderRadius="full">
                            <TagLabel>{group.name}</TagLabel>
                            <TagCloseButton
                              onClick={() => handleRemoveFromGroup(group.group_id)}
                            />
                          </Tag>
                        </WrapItem>
                      ))}
                      {cameraGroups.length === 0 && (
                        <Text fontSize="sm" color="gray.500">
                          No groups assigned
                        </Text>
                      )}
                    </Wrap>
                  </Box>

                  <Box>
                    <Text fontSize="sm" fontWeight="medium" mb={2}>
                      Available Groups
                    </Text>
                    <Wrap spacing={2}>
                      {availableGroups.map((group) => (
                        <WrapItem key={group.group_id}>
                          <Tag
                            size="md"
                            colorScheme="gray"
                            borderRadius="full"
                            cursor="pointer"
                            onClick={() => handleAddToGroup(group.group_id)}
                            _hover={{ bg: 'gray.100' }}
                          >
                            <TagLabel>{group.name}</TagLabel>
                          </Tag>
                        </WrapItem>
                      ))}
                      {availableGroups.length === 0 && (
                        <Text fontSize="sm" color="gray.500">
                          No available groups
                        </Text>
                      )}
                    </Wrap>
                  </Box>
                </VStack>
              </Box>
            </VStack>
          </ModalBody>
          <ModalFooter>
            <Button variant="ghost" mr={3} onClick={() => setIsEditModalOpen(false)}>
              Cancel
            </Button>
            <Button colorScheme="blue" onClick={handleEditSubmit}>
              Save Changes
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default Cameras;
