import React, { useState, useCallback } from 'react';
import {
  VStack,
  HStack,
  Input,
  IconButton,
  Text,
  List,
  ListItem,
  Box,
  Button,
  Tag,
  TagLabel,
  TagCloseButton,
  useColorModeValue,
  Divider,
  InputGroup,
  InputRightElement,
} from '@chakra-ui/react';
import { AddIcon, DeleteIcon, ChevronRightIcon } from '@chakra-ui/icons';

interface RulesInputProps {
  rules: Array<{ title: string; items: string[] }>;
  selectedRules: string[];
  onAddRule: (rule: string) => void;
  onRemoveRule: (rule: string) => void;
}

const RulesInput: React.FC<RulesInputProps> = ({
  rules,
  selectedRules,
  onAddRule,
  onRemoveRule,
}) => {
  const [customRule, setCustomRule] = useState('');

  const bg = useColorModeValue('white', 'gray.800');
  const borderColor = useColorModeValue('gray.200', 'gray.600');
  const textColor = useColorModeValue('gray.800', 'white');
  const mutedColor = useColorModeValue('gray.600', 'gray.400');
  const sectionTitleColor = useColorModeValue('blue.600', 'blue.300');
  const tagBg = useColorModeValue('blue.50', 'blue.900');
  const tagColor = useColorModeValue('blue.800', 'blue.100');
  const customRuleBg = useColorModeValue('gray.50', 'gray.700');
  const hoverBg = useColorModeValue('gray.50', 'gray.700');

  const handleAddCustomRule = useCallback(() => {
    if (customRule.trim()) {
      onAddRule(customRule.trim());
      setCustomRule('');
    }
  }, [customRule, onAddRule]);

  const handleKeyPress = useCallback((e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter' && customRule.trim()) {
      handleAddCustomRule();
    }
  }, [customRule, handleAddCustomRule]);

  return (
    <VStack align="stretch" spacing={6}>
      {/* Custom Rule Input */}
      <Box>
        <Text fontWeight="bold" fontSize="lg" color={sectionTitleColor} mb={2}>
          Add Custom Rule
        </Text>
        <InputGroup size="md">
          <Input
            value={customRule}
            onChange={(e) => setCustomRule(e.target.value)}
            placeholder="Enter your custom rule..."
            bg={customRuleBg}
            color={textColor}
            borderColor={borderColor}
            _placeholder={{ color: mutedColor }}
            _hover={{ borderColor: 'blue.400' }}
            _focus={{ borderColor: 'blue.500', boxShadow: 'outline' }}
            onKeyPress={handleKeyPress}
            pr="4.5rem"
          />
          <InputRightElement width="4.5rem">
            <Button
              h="1.75rem"
              size="sm"
              colorScheme="blue"
              onClick={handleAddCustomRule}
              isDisabled={!customRule.trim()}
            >
              Add
            </Button>
          </InputRightElement>
        </InputGroup>
      </Box>

      <Divider borderColor={borderColor} />

      {/* Selected Rules */}
      {selectedRules.length > 0 && (
        <Box>
          <Text fontWeight="bold" fontSize="lg" color={sectionTitleColor} mb={2}>
            Selected Rules
          </Text>
          <HStack spacing={2} flexWrap="wrap">
            {selectedRules.map((rule, index) => (
              <Tag
                key={index}
                size="md"
                borderRadius="full"
                variant="solid"
                bg={tagBg}
                color={tagColor}
              >
                <TagLabel>{rule}</TagLabel>
                <TagCloseButton onClick={() => onRemoveRule(rule)} />
              </Tag>
            ))}
          </HStack>
        </Box>
      )}

      <Divider borderColor={borderColor} />

      {/* Predefined Rules */}
      {rules.map((section, idx) => (
        <Box key={idx}>
          <Text fontWeight="bold" fontSize="lg" color={sectionTitleColor} mb={2}>
            {section.title}
          </Text>
          <List spacing={2}>
            {section.items.map((item, itemIdx) => (
              <ListItem
                key={itemIdx}
                display="flex"
                alignItems="center"
                p={2}
                borderRadius="md"
                bg={bg}
                borderWidth="1px"
                borderColor={borderColor}
                _hover={{ bg: hoverBg }}
              >
                <HStack flex="1" spacing={3}>
                  <ChevronRightIcon color={mutedColor} />
                  <Text color={textColor} flex="1">
                    {item}
                  </Text>
                  <IconButton
                    icon={selectedRules.includes(item) ? <DeleteIcon /> : <AddIcon />}
                    aria-label={selectedRules.includes(item) ? 'Remove rule' : 'Add rule'}
                    size="sm"
                    colorScheme={selectedRules.includes(item) ? 'red' : 'blue'}
                    variant="ghost"
                    onClick={() =>
                      selectedRules.includes(item) ? onRemoveRule(item) : onAddRule(item)
                    }
                  />
                </HStack>
              </ListItem>
            ))}
          </List>
        </Box>
      ))}
    </VStack>
  );
};

export default RulesInput;
