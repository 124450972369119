export const retailSurveillanceRules = [
  {
    title: 'Customer Traffic',
    items: [
      'Count customers and staff members',
      'Measure queue length and shelf occupancy',
      'Check checkout counter status and self-checkout availability',
      'Count shopping baskets and carts',
      'Assess store crowding',
      'Check for unattended items and spill hazards',
      'Count security personnel',
      'Monitor children and emergency exits',
      'Check for fallen display items',
      'Assess social distancing and mask compliance',
      'Monitor for suspicious activities and customer assistance needs'
    ]
  }
];
