import React from 'react';
import {
  Box,
  Grid,
  Text,
  VStack,
  HStack,
  Badge,
  useColorModeValue,
  Icon,
  Flex,
} from '@chakra-ui/react';
import { 
  FaUsers,
  FaUserAlt,
  FaUserCheck,
  FaChair,
  FaBed,
  FaHardHat,
  FaMask,
  FaCar,
  FaVideo,
  FaCreditCard,
  FaShoppingBag,
  FaExclamationTriangle,
  FaTrash,
  FaClock,
  FaInfoCircle,
} from 'react-icons/fa';
import { GiPistolGun } from 'react-icons/gi';

type ATMData = {
  [key: string]: number | boolean | string;
};

interface ATMMonitoringCardProps {
  data: ATMData;
}

const ATMMonitoringCard: React.FC<ATMMonitoringCardProps> = ({ data }) => {
  const cardBg = useColorModeValue('white', 'gray.800');
  const borderColor = useColorModeValue('gray.200', 'gray.600');
  const timeColor = useColorModeValue('blue.600', 'blue.300');
  const isDark = useColorModeValue(false, true);

  // Helper function to format field names for display
  const formatFieldName = (field: string): string => {
    return field
      .replace(/_/g, ' ')
      .replace(/count/g, '')
      .split(' ')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ')
      .trim();
  };

  // Separate different types of values
  const numericFields: { [key: string]: number } = {};
  const booleanFields: { [key: string]: boolean } = {};
  const timeField = data.time_of_event as string;
  const threatValue = typeof data.threat === 'string' ? data.threat : null;

  Object.entries(data).forEach(([key, value]) => {
    console.log(`Processing field: ${key}, type: ${typeof value}, value:`, value);
    if (typeof value === 'number') {
      numericFields[key] = value;
    } else if (typeof value === 'boolean' && key !== 'threat') {
      console.log(`Adding boolean field: ${key} = ${value}`);
      booleanFields[key] = value;
    }
  });

  console.log('Boolean fields:', booleanFields);
  console.log('Threat value:', threatValue);

  const getIconForField = (key: string): React.ElementType => {
    if (key.includes('people') || key.includes('person')) return FaUsers;
    if (key.includes('real_person')) return FaUserCheck;
    if (key.includes('sitting')) return FaChair;
    if (key.includes('lying')) return FaBed;
    if (key.includes('helmet')) return FaHardHat;
    if (key.includes('mask')) return FaMask;
    if (key.includes('vehicle')) return FaCar;
    if (key.includes('face')) return FaUserAlt;
    if (key.includes('atm')) return FaCreditCard;
    if (key.includes('bag')) return FaShoppingBag;
    if (key.includes('weapon')) return GiPistolGun;
    if (key.includes('dustbin')) return FaTrash;
    if (key.includes('time')) return FaClock;
    return FaInfoCircle;
  };

  const getSentimentColor = (key: string, value: number | boolean): { bg: string; text: string } => {
    // Handle boolean values
    if (typeof value === 'boolean') {
      if (key.includes('weapon')) {
        return value ? 
          { bg: isDark ? 'red.900' : 'red.50', text: isDark ? 'red.200' : 'red.600' } :
          { bg: isDark ? 'green.900' : 'green.50', text: isDark ? 'green.200' : 'green.600' };
      }
      return value ?
        { bg: isDark ? 'blue.900' : 'blue.50', text: isDark ? 'blue.200' : 'blue.600' } :
        { bg: isDark ? 'gray.700' : 'gray.50', text: isDark ? 'gray.200' : 'gray.600' };
    }

    // Handle numeric values
    if (key.includes('person') || key.includes('people')) {
      if (value > 3) return { bg: isDark ? 'red.900' : 'red.50', text: isDark ? 'red.200' : 'red.600' };
      if (value > 1) return { bg: isDark ? 'yellow.900' : 'yellow.50', text: isDark ? 'yellow.200' : 'yellow.600' };
      return { bg: isDark ? 'green.900' : 'green.50', text: isDark ? 'green.200' : 'green.600' };
    }

    if (key.includes('atm')) {
      if (value === 0) return { bg: isDark ? 'red.900' : 'red.50', text: isDark ? 'red.200' : 'red.600' };
      if (value > 1) return { bg: isDark ? 'yellow.900' : 'yellow.50', text: isDark ? 'yellow.200' : 'yellow.600' };
      return { bg: isDark ? 'green.900' : 'green.50', text: isDark ? 'green.200' : 'green.600' };
    }

    if (key.includes('bag')) {
      if (value > 2) return { bg: isDark ? 'red.900' : 'red.50', text: isDark ? 'red.200' : 'red.600' };
      return { bg: isDark ? 'blue.900' : 'blue.50', text: isDark ? 'blue.200' : 'blue.600' };
    }

    // Default color scheme
    return { bg: isDark ? 'blue.900' : 'blue.50', text: isDark ? 'blue.200' : 'blue.600' };
  };

  const getThreatColor = (threat: string | null): { bg: string; text: string } => {
    if (!threat) return { bg: isDark ? 'gray.700' : 'gray.50', text: isDark ? 'gray.200' : 'gray.600' };
    
    if (threat.toLowerCase().includes('fire')) {
      return { bg: isDark ? 'red.900' : 'red.50', text: isDark ? 'red.200' : 'red.600' };
    }
    if (threat.toLowerCase().includes('suspicious')) {
      return { bg: isDark ? 'yellow.900' : 'yellow.50', text: isDark ? 'yellow.200' : 'yellow.600' };
    }
    return { bg: isDark ? 'orange.900' : 'orange.50', text: isDark ? 'orange.200' : 'orange.600' };
  };

  const StatBox = ({ 
    label, 
    value, 
    icon,
    isAlert = false,
  }: { 
    label: string; 
    value: number | boolean; 
    icon: React.ElementType;
    isAlert?: boolean;
  }) => {
    const colors = getSentimentColor(label.toLowerCase(), value);

    return (
      <Box
        p={4}
        borderRadius="lg"
        bg={colors.bg}
        position="relative"
        overflow="hidden"
      >
        <VStack spacing={1} align="start">
          <HStack spacing={2}>
            <Icon as={icon} color={colors.text} boxSize={5} />
            <Text fontSize="sm" fontWeight="medium" color={colors.text}>
              {label}
            </Text>
          </HStack>
          <Text fontSize="2xl" fontWeight="bold" color={colors.text}>
            {typeof value === 'boolean' ? (value ? 'Yes' : 'No') : value}
          </Text>
        </VStack>
      </Box>
    );
  };

  const AlertBadge = ({ isActive, label }: { isActive: boolean; label: string }) => {
    const colors = getSentimentColor(label.toLowerCase(), isActive);
    return (
      <Badge
        bg={colors.bg}
        color={colors.text}
        px={2}
        py={1}
        borderRadius="full"
        fontSize="sm"
      >
        {label}: {isActive ? 'Yes' : 'No'}
      </Badge>
    );
  };

  // Group numeric fields by category
  const categorizeNumeric = (key: string): string => {
    if (key.includes('person') || key.includes('people') || key.includes('face')) {
      return 'People Detection';
    } else if (key.includes('atm')) {
      return 'ATM Status';
    } else if (key.includes('bag') || key.includes('dustbin')) {
      return 'Objects';
    } else {
      return 'Other Metrics';
    }
  };

  // Group numeric fields
  const groupedNumericFields: { [category: string]: { [key: string]: number } } = {};
  Object.entries(numericFields).forEach(([key, value]) => {
    if (key !== 'time_of_event') {
      const category = categorizeNumeric(key);
      if (!groupedNumericFields[category]) {
        groupedNumericFields[category] = {};
      }
      groupedNumericFields[category][key] = value;
    }
  });

  return (
    <Box
      bg={cardBg}
      p={6}
      borderRadius="xl"
      boxShadow="xl"
      border="1px"
      borderColor={borderColor}
    >
      <VStack spacing={6} align="stretch">
        {/* Time of Event */}
        <Flex align="center" gap={2}>
          <Icon as={FaClock} color={timeColor} />
          <Text color={timeColor} fontSize="md" fontWeight="medium">
            {timeField}
          </Text>
        </Flex>

        {/* Threat Alert */}
        {threatValue && (
          <Box mb={4}>
            <Text fontSize="lg" fontWeight="bold" mb={3}>
              Active Threat Alert
            </Text>
            <Box
              p={4}
              borderRadius="lg"
              {...getThreatColor(threatValue)}
              position="relative"
              overflow="hidden"
            >
              <VStack spacing={1} align="start">
                <HStack spacing={2}>
                  <Icon 
                    as={FaExclamationTriangle} 
                    color={getThreatColor(threatValue).text} 
                    boxSize={6} 
                  />
                  <Text 
                    fontSize="lg" 
                    fontWeight="bold" 
                    color={getThreatColor(threatValue).text}
                  >
                    {threatValue}
                  </Text>
                </HStack>
              </VStack>
            </Box>
          </Box>
        )}

        {/* Security Alerts */}
        {Object.keys(booleanFields).length > 0 && (
          <Box>
            <Text fontSize="lg" fontWeight="bold" mb={3}>
              Security Alerts
            </Text>
            <Grid templateColumns="repeat(auto-fill, minmax(200px, 1fr))" gap={4}>
              {Object.entries(booleanFields).map(([key, value]) => {
                console.log(`Rendering boolean field: ${key} = ${value}`);
                return (
                  <StatBox
                    key={key}
                    label={formatFieldName(key)}
                    value={value}
                    icon={getIconForField(key)}
                  />
                );
              })}
            </Grid>
          </Box>
        )}

        {/* Numeric Metrics */}
        {Object.entries(groupedNumericFields).map(([category, fields]) => (
          <Box key={category}>
            <Text fontSize="lg" fontWeight="bold" mb={3}>
              {category}
            </Text>
            <Grid templateColumns={['repeat(2, 1fr)', 'repeat(3, 1fr)', 'repeat(4, 1fr)']} gap={4}>
              {Object.entries(fields).map(([key, value]) => (
                <StatBox
                  key={key}
                  label={formatFieldName(key)}
                  value={value}
                  icon={getIconForField(key)}
                />
              ))}
            </Grid>
          </Box>
        ))}
      </VStack>
    </Box>
  );
};

export default ATMMonitoringCard;
