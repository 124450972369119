import React, { useEffect, useState, useCallback } from 'react';
import {
  Box,
  Container,
  Heading,
  SimpleGrid,
  Text,
  Badge,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  useDisclosure,
  VStack,
  Image,
  Divider,
  useToast,
} from '@chakra-ui/react';
import { casesApi } from '../services/api';

interface Case {
  id: number;
  image_data: string;
  analysis_text: string;
  for_human: boolean;
  processed_by_ai: boolean;
  created_at: string;
  people_count: number;
  real_person_count: number;
  person_sitting: number;
  person_lying_down: number;
  helmet_count: number;
  face_mask_count: number;
  vehicle_count: number;
  face_count: number;
  atm_count: number;
  bag_count: number;
  weapon: boolean;
  threat: boolean;
  dustbin_count: number;
  time_of_event: string;
}

interface ValidationResult {
  criteria_name: string;
  field: string;
  operator: string;
  expected_value: number;
  actual_value: number;
  is_valid: boolean;
}

interface ValidationResponse {
  case_id: number;
  validation_results: ValidationResult[];
  all_criteria_met: boolean;
}

const Cases: React.FC = () => {
  const [cases, setCases] = useState<Case[]>([]);
  const [selectedCase, setSelectedCase] = useState<Case | null>(null);
  const [validationResults, setValidationResults] = useState<ValidationResponse | null>(null);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isLoading, setIsLoading] = useState(false);
  const toast = useToast();

  const fetchCases = useCallback(async () => {
    try {
      setIsLoading(true);
      const response = await casesApi.getCases();
      setCases(response);
    } catch (error) {
      console.error('Error fetching cases:', error);
      toast({
        title: 'Error',
        description: 'Failed to fetch cases',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setIsLoading(false);
    }
  }, [toast]);

 useEffect(() => {
   void fetchCases();
 }, [fetchCases]);

  const handleCaseClick = async (caseItem: Case) => {
    setSelectedCase(caseItem);
    try {
      const results = await casesApi.validateCase(caseItem.id);
      setValidationResults(results);
    } catch (error) {
      console.error('Error validating case:', error);
      toast({
        title: 'Error',
        description: 'Failed to validate case',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
    onOpen();
  };
  

  return (
    <Container maxW="container.xl" py={8}>
      <Heading mb={6}>ATM Surveillance Cases</Heading>
      
      {isLoading ? (
        <Box textAlign="center" py={10}>
          Loading cases...
        </Box>
      ) : (
        <SimpleGrid columns={[1, 2, 3]} spacing={6}>
        {cases.map((caseItem) => (
          <Box
            key={caseItem.id}
            borderWidth="1px"
            borderRadius="lg"
            overflow="hidden"
            cursor="pointer"
            onClick={() => handleCaseClick(caseItem)}
            _hover={{ shadow: 'lg' }}
          >
            <Image
              src={caseItem.image_data}
              alt={`Case ${caseItem.id}`}
              height="200px"
              width="100%"
              objectFit="cover"
            />
            <Box p={4}>
              <Badge colorScheme={caseItem.threat ? 'red' : 'green'} mb={2}>
                {caseItem.threat ? 'Threat Detected' : 'No Threat'}
              </Badge>
              <Text fontSize="sm" color="gray.500">
                Time: {caseItem.time_of_event}
              </Text>
              <Text fontSize="sm" color="gray.500">
                People: {caseItem.people_count}
              </Text>
            </Box>
          </Box>
        ))}
      </SimpleGrid>
      )}

      <Modal isOpen={isOpen} onClose={onClose} size="xl">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Case Details</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {selectedCase && (
              <VStack spacing={4} align="stretch">
                <Image
                  src={selectedCase.image_data}
                  alt={`Case ${selectedCase.id}`}
                  maxH="400px"
                  objectFit="contain"
                />
                
                <Box>
                  <Heading size="md">Analysis</Heading>
                  <Text mt={2}>{selectedCase.analysis_text}</Text>
                </Box>

                <Divider />

                <Box>
                  <Heading size="md">Metadata</Heading>
                  <SimpleGrid columns={2} spacing={2} mt={2}>
                    <Text>People Count: {selectedCase.people_count}</Text>
                    <Text>Real Person Count: {selectedCase.real_person_count}</Text>
                    <Text>Sitting: {selectedCase.person_sitting}</Text>
                    <Text>Lying Down: {selectedCase.person_lying_down}</Text>
                    <Text>Helmets: {selectedCase.helmet_count}</Text>
                    <Text>Face Masks: {selectedCase.face_mask_count}</Text>
                    <Text>Vehicles: {selectedCase.vehicle_count}</Text>
                    <Text>Faces: {selectedCase.face_count}</Text>
                    <Text>ATMs: {selectedCase.atm_count}</Text>
                    <Text>Bags: {selectedCase.bag_count}</Text>
                    <Text>Weapon: {selectedCase.weapon ? 'Yes' : 'No'}</Text>
                    <Text>Threat: {selectedCase.threat ? 'Yes' : 'No'}</Text>
                    <Text>Dustbins: {selectedCase.dustbin_count}</Text>
                    <Text>Time: {selectedCase.time_of_event}</Text>
                  </SimpleGrid>
                </Box>

                {validationResults && (
                  <>
                    <Divider />
                    <Box>
                      <Heading size="md">Validation Results</Heading>
                      <Badge
                        colorScheme={validationResults.all_criteria_met ? 'green' : 'red'}
                        mt={2}
                      >
                        {validationResults.all_criteria_met
                          ? 'All Criteria Met'
                          : 'Some Criteria Failed'}
                      </Badge>
                      <VStack spacing={2} mt={4} align="stretch">
                        {validationResults.validation_results.map((result, index) => (
                          <Box
                            key={index}
                            p={2}
                            borderWidth="1px"
                            borderRadius="md"
                            borderColor={result.is_valid ? 'green.200' : 'red.200'}
                            bg={result.is_valid ? 'green.50' : 'red.50'}
                          >
                            <Text fontWeight="bold">{result.criteria_name}</Text>
                            <Text fontSize="sm">
                              {result.field} {result.operator} {result.expected_value}
                              {' (Actual: '}{result.actual_value}{')'} 
                            </Text>
                          </Box>
                        ))}
                      </VStack>
                    </Box>
                  </>
                )}
              </VStack>
            )}
          </ModalBody>
          <ModalFooter>
            <Button onClick={onClose}>Close</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Container>
  );
};

export default Cases;
