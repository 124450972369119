import React from 'react';
import {
  Box,
  Grid,
  Text,
  VStack,
  HStack,
  Badge,
  useColorModeValue,
  Icon,
} from '@chakra-ui/react';
import { 
  FaUsers, 
  FaCar, 
  FaParking, 
  FaWalking, 
  FaExclamationTriangle,
  FaTools,
  FaTrafficLight,
  FaCarCrash,
  FaInfoCircle,
} from 'react-icons/fa';

type TrafficData = {
  [key: string]: number | boolean | string;
};

interface TrafficMonitoringCardProps {
  data: TrafficData;
}

const TrafficMonitoringCard: React.FC<TrafficMonitoringCardProps> = ({ data }) => {
  const cardBg = useColorModeValue('white', 'gray.800');
  const borderColor = useColorModeValue('gray.200', 'gray.600');
  const isDark = useColorModeValue(false, true);

  const formatFieldName = (field: string): string => {
    return field
      .replace(/_/g, ' ')
      .replace(/is /g, '')
      .split(' ')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  };

  const numericFields: { [key: string]: number } = {};
  const booleanFields: { [key: string]: boolean } = {};

  Object.entries(data).forEach(([key, value]) => {
    if (typeof value === 'number') {
      numericFields[key] = value;
    } else if (typeof value === 'boolean') {
      booleanFields[key] = value;
    }
  });

  const getSentimentColor = (key: string, value: number | boolean): { bg: string; text: string } => {
    // Handle boolean values
    if (typeof value === 'boolean') {
      if (key.includes('congested') || key.includes('accident') || key.includes('debris')) {
        return value ? 
          { bg: isDark ? 'red.900' : 'red.50', text: isDark ? 'red.200' : 'red.600' } :
          { bg: isDark ? 'green.900' : 'green.50', text: isDark ? 'green.200' : 'green.600' };
      }
      return value ?
        { bg: isDark ? 'blue.900' : 'blue.50', text: isDark ? 'blue.200' : 'blue.600' } :
        { bg: isDark ? 'gray.700' : 'gray.50', text: isDark ? 'gray.200' : 'gray.600' };
    }

    // Handle numeric values
    if (key.includes('vehicle')) {
      if (value > 10) return { bg: isDark ? 'red.900' : 'red.50', text: isDark ? 'red.200' : 'red.600' };
      if (value > 5) return { bg: isDark ? 'yellow.900' : 'yellow.50', text: isDark ? 'yellow.200' : 'yellow.600' };
      return { bg: isDark ? 'green.900' : 'green.50', text: isDark ? 'green.200' : 'green.600' };
    }

    if (key.includes('people') || key.includes('pedestrian')) {
      if (value > 20) return { bg: isDark ? 'red.900' : 'red.50', text: isDark ? 'red.200' : 'red.600' };
      if (value > 10) return { bg: isDark ? 'yellow.900' : 'yellow.50', text: isDark ? 'yellow.200' : 'yellow.600' };
      return { bg: isDark ? 'green.900' : 'green.50', text: isDark ? 'green.200' : 'green.600' };
    }

    if (key.includes('violation')) {
      if (value > 0) return { bg: isDark ? 'red.900' : 'red.50', text: isDark ? 'red.200' : 'red.600' };
      return { bg: isDark ? 'green.900' : 'green.50', text: isDark ? 'green.200' : 'green.600' };
    }

    // Default color scheme
    return { bg: isDark ? 'blue.900' : 'blue.50', text: isDark ? 'blue.200' : 'blue.600' };
  };

  const StatBox = ({ 
    label, 
    value, 
    icon,
    isAlert = false,
  }: { 
    label: string; 
    value: number | boolean; 
    icon: React.ElementType;
    isAlert?: boolean;
  }) => {
    const colors = getSentimentColor(label.toLowerCase(), value);

    return (
      <Box
        p={4}
        borderRadius="lg"
        bg={colors.bg}
        position="relative"
        overflow="hidden"
      >
        <VStack spacing={1} align="start">
          <HStack spacing={2}>
            <Icon as={icon} color={colors.text} boxSize={5} />
            <Text fontSize="sm" fontWeight="medium" color={colors.text}>
              {label}
            </Text>
          </HStack>
          <Text fontSize="2xl" fontWeight="bold" color={colors.text}>
            {typeof value === 'boolean' ? (value ? 'Yes' : 'No') : value}
          </Text>
        </VStack>
      </Box>
    );
  };

  const AlertBadge = ({ isActive, label }: { isActive: boolean; label: string }) => {
    const colors = getSentimentColor(label.toLowerCase(), isActive);
    return (
      <Badge
        bg={colors.bg}
        color={colors.text}
        px={2}
        py={1}
        borderRadius="full"
      >
        {label}: {isActive ? 'Yes' : 'No'}
      </Badge>
    );
  };

  const categorizeBoolean = (key: string): string => {
    if (key.includes('damaged') || key.includes('signals') || key.includes('lights') || key.includes('barrier')) {
      return 'Infrastructure Status';
    } else if (key.includes('accident') || key.includes('safety') || key.includes('congested') || key.includes('debris')) {
      return 'Critical Alerts';
    } else {
      return 'Other Status';
    }
  };

  const groupedBooleanFields: { [category: string]: { [key: string]: boolean } } = {};
  Object.entries(booleanFields).forEach(([key, value]) => {
    const category = categorizeBoolean(key);
    if (!groupedBooleanFields[category]) {
      groupedBooleanFields[category] = {};
    }
    groupedBooleanFields[category][key] = value;
  });

  return (
    <Box
      bg={cardBg}
      p={6}
      borderRadius="xl"
      boxShadow="xl"
      border="1px"
      borderColor={borderColor}
    >
      <VStack spacing={6} align="stretch">
        {Object.keys(numericFields).length > 0 && (
          <Box>
            <Text fontSize="lg" fontWeight="bold" mb={3}>
              Metrics
            </Text>
            <Grid templateColumns={['repeat(2, 1fr)', 'repeat(3, 1fr)', 'repeat(4, 1fr)']} gap={4}>
              {Object.entries(numericFields).map(([key, value]) => (
                <StatBox
                  key={key}
                  label={formatFieldName(key)}
                  value={value}
                  icon={key.includes('vehicle') ? FaCar : 
                        key.includes('parking') ? FaParking :
                        key.includes('pedestrian') || key.includes('people') ? FaUsers :
                        key.includes('violation') ? FaExclamationTriangle :
                        FaInfoCircle}
                />
              ))}
            </Grid>
          </Box>
        )}

        {Object.entries(groupedBooleanFields).map(([category, fields]) => (
          <Box key={category}>
            <Text fontSize="lg" fontWeight="bold" mb={3}>
              {category}
            </Text>
            <Grid templateColumns={['repeat(2, 1fr)', 'repeat(3, 1fr)']} gap={3}>
              {Object.entries(fields).map(([key, value]) => (
                <AlertBadge
                  key={key}
                  isActive={value}
                  label={formatFieldName(key)}
                />
              ))}
            </Grid>
          </Box>
        ))}
      </VStack>
    </Box>
  );
};

export default TrafficMonitoringCard;
