import React, { useEffect, useState } from 'react';
import {
  Box,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Button,
  useDisclosure,
  Text,
  HStack,
  Badge,
} from '@chakra-ui/react';
import { AddIcon } from '@chakra-ui/icons';
import { Site } from '../../types/site';
import { siteService } from '../../services/siteService';
import SiteModal from './SiteModal';

const formatDate = (dateString: string) => {
  const date = new Date(dateString);
  return date.toLocaleString('en-US', {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
  });
};

const SiteList: React.FC = () => {
  const [sites, setSites] = useState<Site[]>([]);
  const [selectedSite, setSelectedSite] = useState<Site | null>(null);
  const { isOpen, onOpen, onClose } = useDisclosure();

  const fetchSites = async () => {
    try {
      const response = await siteService.getSites();
      setSites(response.sites || []);
    } catch (error) {
      console.error('Error fetching sites:', error);
      setSites([]);
    }
  };

  useEffect(() => {
    fetchSites();
  }, []);

  const handleAddSite = () => {
    setSelectedSite(null);
    onOpen();
  };

  const handleEditSite = (site: Site) => {
    setSelectedSite(site);
    onOpen();
  };

  const handleSaveSite = async () => {
    await fetchSites();
    onClose();
  };

  return (
    <Box>
      <HStack justify="space-between" mb={4}>
        <Text fontSize="xl" fontWeight="bold">Sites</Text>
        <Button leftIcon={<AddIcon />} colorScheme="blue" onClick={handleAddSite}>
          Add Site
        </Button>
      </HStack>

      <Table variant="simple">
        <Thead>
          <Tr>
            <Th key="location_name">Location Name</Th>
            <Th key="address">Address</Th>
            <Th key="admin">Admin</Th>
            <Th key="contact">Contact</Th>
            <Th key="channel_count">Channel Count</Th>
            <Th key="created_at">Created Date</Th>
            <Th key="actions">Actions</Th>
          </Tr>
        </Thead>
        <Tbody>
          {sites.map((site) => (
            <Tr key={site.site_id}>
              <Td>{site.location_name}</Td>
              <Td>{site.address}</Td>
              <Td>{site.admin_name || 'N/A'}</Td>
              <Td>{site.admin_contact_number || 'N/A'}</Td>
              <Td>
                <Badge colorScheme="blue">
                  {site.channels?.length || 0}
                </Badge>
              </Td>
              <Td>{formatDate(site.created_at)}</Td>
              <Td>
                <Button size="sm" onClick={() => handleEditSite(site)}>
                  Edit
                </Button>
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>

      <SiteModal
        isOpen={isOpen}
        onClose={onClose}
        site={selectedSite}
        onSave={handleSaveSite}
      />
    </Box>
  );
};

export default SiteList;
