import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  Card,
  CardBody,
  Grid,
  GridItem,
  Heading,
  HStack,
  IconButton,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  Text,
  Textarea,
  useDisclosure,
  VStack,
  useToast,
  useColorModeValue,
  Badge,
  Spinner,
  FormControl,
  FormLabel,
  FormErrorMessage,
} from '@chakra-ui/react';
import { AddIcon, EditIcon, DeleteIcon } from '@chakra-ui/icons';
import { Agent, AgentCreate, agentApi } from '../services/api';

const AgentManagement: React.FC = () => {
  const [agents, setAgents] = useState<Agent[]>([]);
  const [selectedAgent, setSelectedAgent] = useState<Agent | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [formError, setFormError] = useState<string | null>(null);
  const [profiles, setProfiles] = useState<{ id: number; name: string }[]>([
    { id: 1, name: 'Default Profile' },
    { id: 2, name: 'High Security Profile' },
    { id: 3, name: 'Standard Profile' },
    { id: 4, name: 'Night Shift Profile' },
  ]);
  const [newAgent, setNewAgent] = useState<AgentCreate>({
    name: '',
    description: '',
    status: 'active',
    profile_id: 1,
    is_default: false,
  });

  const { isOpen, onOpen, onClose } = useDisclosure();
  const toast = useToast();

  // Color mode values
  const bg = useColorModeValue('white', 'gray.800');
  const borderColor = useColorModeValue('gray.200', 'gray.600');
  const cardBg = useColorModeValue('white', 'gray.700');
  const textColor = useColorModeValue('gray.800', 'white');
  const mutedColor = useColorModeValue('gray.600', 'gray.300');

  const fetchAgents = async () => {
    setIsLoading(true);
    setError(null);
    try {
      const data = await agentApi.getAgents();
      console.log('Fetched agents:', data);
      if (Array.isArray(data)) {
        setAgents(data);
      } else {
        console.error('Invalid response format:', data);
        setError('Invalid data format received from server');
      }
    } catch (error: any) {
      console.error('Error fetching agents:', error);
      const errorMessage = error.response?.data?.detail || 'Failed to fetch agents. Please try again.';
      setError(errorMessage);
      toast({
        title: 'Error',
        description: errorMessage,
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchAgents();
  }, []);

  const handleCreateAgent = async () => {
    setFormError(null);
    if (!newAgent.name.trim()) {
      setFormError('Agent name is required');
      return;
    }

    try {
      await agentApi.createAgent(newAgent);
      toast({
        title: 'Success',
        description: 'Agent created successfully',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
      onClose();
      fetchAgents();
    } catch (error: any) {
      console.error('Error creating agent:', error);
      const errorMessage = error.response?.data?.detail || 'Failed to create agent';
      setFormError(errorMessage);
      toast({
        title: 'Error',
        description: errorMessage,
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const handleUpdateAgent = async () => {
    if (!selectedAgent) return;
    setFormError(null);

    try {
      await agentApi.updateAgent(selectedAgent.id, {
        name: newAgent.name,
        description: newAgent.description,
        status: newAgent.status,
        profile_id: newAgent.profile_id,
        is_default: newAgent.is_default,
      });
      toast({
        title: 'Success',
        description: 'Agent updated successfully',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
      onClose();
      fetchAgents();
    } catch (error: any) {
      console.error('Error updating agent:', error);
      const errorMessage = error.response?.data?.detail || 'Failed to update agent';
      setFormError(errorMessage);
      toast({
        title: 'Error',
        description: errorMessage,
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const handleDeleteAgent = async (agentId: number) => {
    try {
      await agentApi.deleteAgent(agentId);
      toast({
        title: 'Success',
        description: 'Agent deleted successfully',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
      fetchAgents();
    } catch (error: any) {
      console.error('Error deleting agent:', error);
      const errorMessage = error.response?.data?.detail || 'Failed to delete agent';
      toast({
        title: 'Error',
        description: errorMessage,
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const handleEditClick = (agent: Agent) => {
    setSelectedAgent(agent);
    setNewAgent({
      name: agent.name,
      description: agent.description || '',
      status: agent.status,
      profile_id: agent.profile_id,
      is_default: agent.is_default,
    });
    setFormError(null);
    onOpen();
  };

  if (isLoading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minH="400px">
        <Spinner size="xl" color="blue.500" />
      </Box>
    );
  }

  if (error) {
    return (
      <Box p={4} textAlign="center">
        <Text color="red.500" mb={4}>{error}</Text>
        <Button colorScheme="blue" onClick={fetchAgents}>
          Retry
        </Button>
      </Box>
    );
  }

  return (
    <Box p={4}>
      <HStack justify="space-between" mb={6}>
        <Heading size="lg" color={textColor}>Agent Management</Heading>
        <Button
          leftIcon={<AddIcon />}
          colorScheme="blue"
          onClick={() => {
            setSelectedAgent(null);
            setNewAgent({
              name: '',
              description: '',
              status: 'active',
              profile_id: 1,
              is_default: false,
            });
            setFormError(null);
            onOpen();
          }}
        >
          Add Agent
        </Button>
      </HStack>

      <Grid templateColumns="repeat(auto-fill, minmax(300px, 1fr))" gap={6}>
        {agents.map((agent) => (
          <GridItem key={agent.id}>
            <Card bg={cardBg} borderColor={borderColor} borderWidth="1px">
              <CardBody>
                <VStack align="start" spacing={3}>
                  <HStack justify="space-between" width="100%">
                    <Heading size="md" color={textColor}>{agent.name}</Heading>
                    <HStack>
                      <IconButton
                        icon={<EditIcon />}
                        aria-label="Edit agent"
                        size="sm"
                        colorScheme="blue"
                        variant="ghost"
                        onClick={() => handleEditClick(agent)}
                        isDisabled={agent.is_default}
                      />
                      <IconButton
                        icon={<DeleteIcon />}
                        aria-label="Delete agent"
                        size="sm"
                        colorScheme="red"
                        variant="ghost"
                        onClick={() => handleDeleteAgent(agent.id)}
                        isDisabled={agent.is_default}
                      />
                    </HStack>
                  </HStack>

                  <Text color={textColor}>{agent.description}</Text>

                  <HStack>
                    <Badge
                      colorScheme={agent.status === 'active' ? 'green' : 'gray'}
                    >
                      {agent.status}
                    </Badge>
                    {agent.is_default && (
                      <Badge colorScheme="blue">Default</Badge>
                    )}
                  </HStack>

                  <Text fontSize="sm" color={mutedColor}>
                    Profile: {agent.profile.name}
                  </Text>

                  {agent.surveillance_rules && agent.surveillance_rules.length > 0 && (
                    <Box>
                      <Text fontSize="sm" fontWeight="bold" color={textColor}>
                        Surveillance Rules:
                      </Text>
                      <HStack flexWrap="wrap" spacing={2}>
                        {agent.surveillance_rules.map((rule) => (
                          <Badge key={rule.id} colorScheme="purple" variant="subtle">
                            {rule.name}
                          </Badge>
                        ))}
                      </HStack>
                    </Box>
                  )}

                  {agent.atms && agent.atms.length > 0 && (
                    <Box>
                      <Text fontSize="sm" fontWeight="bold" color={textColor}>
                        Assigned ATMs:
                      </Text>
                      <HStack flexWrap="wrap" spacing={2}>
                        {agent.atms.map((atm) => (
                          <Badge key={atm.id} colorScheme="teal" variant="subtle">
                            {atm.atm_id}
                          </Badge>
                        ))}
                      </HStack>
                    </Box>
                  )}
                </VStack>
              </CardBody>
            </Card>
          </GridItem>
        ))}
      </Grid>

      {/* Create/Edit Agent Modal */}
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent bg={bg}>
          <ModalHeader color={textColor}>
            {selectedAgent ? 'Edit Agent' : 'Create New Agent'}
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <VStack spacing={4}>
              <FormControl isInvalid={!!formError}>
                <FormLabel color={textColor}>Name</FormLabel>
                <Input
                  placeholder="Agent Name"
                  value={newAgent.name}
                  onChange={(e) => setNewAgent({ ...newAgent, name: e.target.value })}
                  bg={cardBg}
                  color={textColor}
                />
                {formError && <FormErrorMessage>{formError}</FormErrorMessage>}
              </FormControl>

              <FormControl>
                <FormLabel color={textColor}>Description</FormLabel>
                <Textarea
                  placeholder="Description"
                  value={newAgent.description}
                  onChange={(e) => setNewAgent({ ...newAgent, description: e.target.value })}
                  bg={cardBg}
                  color={textColor}
                />
              </FormControl>

              <FormControl>
                <FormLabel color={textColor}>Status</FormLabel>
                <Select
                  value={newAgent.status}
                  onChange={(e) => setNewAgent({ ...newAgent, status: e.target.value })}
                  bg={cardBg}
                  color={textColor}
                >
                  <option value="active">Active</option>
                  <option value="inactive">Inactive</option>
                  <option value="training">Training</option>
                </Select>
              </FormControl>

              <FormControl>
                <FormLabel color={textColor}>Profile</FormLabel>
                <Select
                  value={newAgent.profile_id}
                  onChange={(e) => setNewAgent({ ...newAgent, profile_id: parseInt(e.target.value) })}
                  bg={cardBg}
                  color={textColor}
                >
                  {profiles.map((profile) => (
                    <option key={profile.id} value={profile.id}>
                      {profile.name}
                    </option>
                  ))}
                </Select>
              </FormControl>
            </VStack>
          </ModalBody>
          <ModalFooter>
            <Button variant="ghost" mr={3} onClick={onClose}>
              Cancel
            </Button>
            <Button
              colorScheme="blue"
              onClick={selectedAgent ? handleUpdateAgent : handleCreateAgent}
            >
              {selectedAgent ? 'Update' : 'Create'}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default AgentManagement;
