import React from 'react';
import {
  Box,
  Container,
  VStack,
  Heading,
  useToast,
  Drawer,
  DrawerBody,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  useDisclosure,
  useColorModeValue,
  Button,
  HStack,
  Image,
  SimpleGrid,
  Text,
} from '@chakra-ui/react';
import ChatInput from '../components/ChatInput';
import ChatMessage from '../components/ChatMessage';
import RulesInput from '../components/RulesInput';
import { Message } from '../types/chat';
import { cameraSurveillanceRules, CameraSurveillanceRule } from '../constants/cameraSurveillanceRules';
import { useAuth } from '../contexts/AuthContext';
import { useChatState } from '../hooks/useChatState';
import { DeleteIcon } from '@chakra-ui/icons';
import api from '../services/api';

const CHAT_API_URL = process.env.REACT_APP_API_URL || '/api';
const BOT_TYPE = 'camera-bot';

const CameraBot: React.FC = () => {
  const { isOpen: isRulesOpen, onOpen: onRulesOpen, onClose: onRulesClose } = useDisclosure();
  const { isOpen: isSamplesOpen, onOpen: onSamplesOpen, onClose: onSamplesClose } = useDisclosure();
  const { chatState, setChatState, clearChat } = useChatState(BOT_TYPE);
  const [selectedRules, setSelectedRules] = React.useState<string[]>([]);
  const [selectedSampleImage, setSelectedSampleImage] = React.useState<string | null>(null);
  const toast = useToast();
  const { user } = useAuth();

  const bg = useColorModeValue('white', 'gray.800');
  const borderColor = useColorModeValue('gray.200', 'gray.600');
  const drawerBg = useColorModeValue('white', 'gray.800');
  const drawerColor = useColorModeValue('gray.800', 'white');

  // Sample images data
  const sampleImages = Array.from({ length: 20 }, (_, i) => ({
    id: i + 1,
    url: `/samples/atm${i + 1}.jpg`,
    title: `ATM Sample ${i + 1}`
  }));

  // Transform rules into the format expected by RulesInput
  const transformedRules = React.useMemo(() => {
    const categories = Array.from(new Set(cameraSurveillanceRules.map(rule => rule.category)));
    return categories.map(category => ({
      title: category,
      items: cameraSurveillanceRules
        .filter(rule => rule.category === category)
        .map(rule => rule.description)
    }));
  }, []);

  const handleSampleImageSelect = (imageUrl: string) => {
    setSelectedSampleImage(imageUrl);
    onSamplesClose();
  };

  const handleSendMessage = async (text: string, image?: File) => {
    try {
      setChatState((prevState) => ({ ...prevState, isLoading: true, error: null }));

      let base64Image: string | undefined;
      if (image) {
        const reader = new FileReader();
        base64Image = await new Promise((resolve, reject) => {
          reader.onload = () => resolve(reader.result as string);
          reader.onerror = reject;
          reader.readAsDataURL(image);
        });
      }

      const requestData = {
        message: text,
        image: base64Image,
        user_id: user?.id?.toString() || '',
        username: user?.username || '',
        role: user?.role || '',
        rules: selectedRules || []
      };

      const userMessage: Message = {
        role: 'user',
        content: text,
        image: image ? URL.createObjectURL(image) : undefined,
      };

      setChatState((prevState) => ({
        ...prevState,
        messages: [...prevState.messages, userMessage],
      }));

      const response = await api.post('/ai/chat/completions', requestData, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
      });

      const assistantMessage: Message = {
        role: response.data.role,
        content: response.data.content,
        jsonData: response.data.jsonData,
      };

      setChatState((prevState) => ({
        ...prevState,
        messages: [...prevState.messages, assistantMessage],
        isLoading: false,
      }));
    } catch (error) {
      console.error('Error sending message:', error);
      toast({
        title: 'Error',
        description: 'Failed to send message. Please try again.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
      setChatState((prevState) => ({ ...prevState, isLoading: false, error }));
    }
  };

  const handleAddRule = (rule: string) => {
    if (!selectedRules.includes(rule)) {
      setSelectedRules([...selectedRules, rule]);
    }
  };

  const handleRemoveRule = (rule: string) => {
    setSelectedRules(selectedRules.filter((r) => r !== rule));
  };

  const handleClearChat = async () => {
    await clearChat();
    setSelectedRules([]); // Clear selected rules when clearing chat
    toast({
      title: 'Chat Cleared',
      description: 'All messages and rules have been cleared.',
      status: 'success',
      duration: 3000,
      isClosable: true,
    });
  };

  return (
    <Container maxW="container.xl" p={4}>
      <Box
        h="calc(100vh - 150px)"
        bg={bg}
        borderRadius="lg"
        border="1px"
        borderColor={borderColor}
        overflow="hidden"
        display="flex"
        flexDirection="column"
      >
        <Box p={4} borderBottom="1px" borderColor={borderColor}>
          <HStack justify="space-between">
            <Heading size="md">Camera Monitoring Agent</Heading>
            <Button
              leftIcon={<DeleteIcon />}
              colorScheme="red"
              variant="ghost"
              size="sm"
              onClick={handleClearChat}
            >
              Clear Chat
            </Button>
          </HStack>
        </Box>

        <Box flex="1" overflowY="auto" p={4}>
          <VStack spacing={4} align="stretch">
            {chatState.messages.map((message, index) => (
              <ChatMessage key={index} message={message} />
            ))}
          </VStack>
        </Box>

        <Box p={4} borderTop="1px" borderColor={borderColor}>
          <ChatInput
            onSendMessage={handleSendMessage}
            isLoading={chatState.isLoading}
            onRulesClick={onRulesOpen}
            onSamplesClick={onSamplesOpen}
            selectedRules={selectedRules}
            selectedSampleImage={selectedSampleImage}
          />
        </Box>
      </Box>

      {/* Rules Drawer */}
      <Drawer isOpen={isRulesOpen} placement="right" onClose={onRulesClose} size="md">
        <DrawerOverlay />
        <DrawerContent bg={drawerBg} color={drawerColor}>
          <DrawerCloseButton />
          <DrawerHeader borderBottomWidth="1px" borderColor={borderColor}>
            Surveillance Rules
          </DrawerHeader>
          <DrawerBody>
            <RulesInput
              rules={transformedRules}
              selectedRules={selectedRules}
              onAddRule={handleAddRule}
              onRemoveRule={handleRemoveRule}
            />
          </DrawerBody>
        </DrawerContent>
      </Drawer>

      {/* Sample Images Drawer */}
      <Drawer isOpen={isSamplesOpen} placement="right" onClose={onSamplesClose} size="lg">
        <DrawerOverlay />
        <DrawerContent bg={drawerBg} color={drawerColor}>
          <DrawerCloseButton />
          <DrawerHeader borderBottomWidth="1px" borderColor={borderColor}>
            Sample Images
          </DrawerHeader>
          <DrawerBody>
            <SimpleGrid columns={[1, 2, 3]} spacing={4} py={2}>
              {sampleImages.map((image) => (
                <Box
                  key={image.id}
                  cursor="pointer"
                  onClick={() => handleSampleImageSelect(image.url)}
                  borderWidth="1px"
                  borderRadius="lg"
                  overflow="hidden"
                  _hover={{ 
                    shadow: 'lg',
                    transform: 'scale(1.02)',
                    transition: 'all 0.2s ease-in-out'
                  }}
                >
                  <Box position="relative" paddingTop="75%">
                    <Image 
                      src={image.url} 
                      alt={image.title}
                      position="absolute"
                      top="0"
                      left="0"
                      width="100%"
                      height="100%"
                      objectFit="cover"
                    />
                  </Box>
                  <Text p={2} fontSize="sm" textAlign="center">
                    {image.title}
                  </Text>
                </Box>
              ))}
            </SimpleGrid>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </Container>
  );
};

export default CameraBot;
