import axios, { AxiosRequestConfig } from 'axios';

const API_BASE_URL = process.env.REACT_APP_API_URL || 'https://vidiogpt.com/api';

export type UserRole = 'admin' | 'technician' | 'user';

export interface LoginCredentials {
  username: string;
  password: string;
}

export interface RegisterData {
  email: string;
  username: string;
  password: string;
  role: UserRole;
}

export interface User {
  id: number;
  email: string;
  username: string;
  role: UserRole;
  is_active: boolean;
}

export interface Token {
  access_token: string;
  token_type: string;
}

export interface CameraCreate {
  camera_id: string;
  location: string;
  status: string;
  stream_url: string;
}

export interface CameraResponse extends CameraCreate {
  id: number;
  last_maintenance: string;
  technician_id: number | null;
}

export interface CameraIssueCreate {
  issue_type: string;
  description: string;
  status: string;
}

export interface CameraIssueResponse extends CameraIssueCreate {
  id: number;
  camera_id: number;
  created_at: string;
  resolved_at: string | null;
}

export interface ChatMessageCreate {
  content: string;
  receiver_id: number;
}

export interface ChatMessageResponse extends ChatMessageCreate {
  id: number;
  sender_id: number;
  created_at: string;
  is_read: boolean;
}

export interface Parameters {
  field: string;
  operator: string;
  value: number;
  [key: string]: any;
}

export interface SurveillanceRule {
  id: number;
  name: string;
  description: string;
  type: string;
  parameters: Parameters;
  is_active: boolean;
  created_at: string;
  updated_at: string;
  created_by?: {
    id: number;
    username: string;
    email: string;
    role: string;
  };
}

export interface SurveillanceRuleCreate {
  name: string;
  description: string;
  type: string;
  parameters: Parameters;
}

export interface Agent {
  id: number;
  name: string;
  description: string;
  status: string;
  is_default: boolean;
  created_at: string;
  updated_at: string;
  profile_id: number;
  profile: {
    id: number;
    name: string;
  };
  surveillance_rules: {
    id: number;
    name: string;
    description: string;
    type: string;
    is_active: boolean;
  }[];
  cameras: {
    id: number;
    camera_id: string;
    location: string;
    status: string;
    stream_url: string;
    last_maintenance: string | null;
  }[];
}

export interface AgentCreate {
  name: string;
  description: string;
  status: string;
  profile_id: number;
  is_default: boolean;
}

export interface Webhook {
  id: number;
  name: string;
  url: string;
  description: string;
  events: string[];
  headers: Record<string, string>;
  is_active: boolean;
  user_id: number;
  created_at: string;
  updated_at: string;
}

export interface WebhookCreate {
  name: string;
  url: string;
  description: string;
  events: string[];
  headers: Record<string, string>;
  is_active: boolean;
}

export type WebhookUpdate = Partial<WebhookCreate>

export interface Integration {
  id: string;
  type: 'email' | 'telegram' | 'whatsapp' | 'pushover' | 'webhook';
  name: string;
  enabled: boolean;
  config: EmailConfig | TelegramConfig | WhatsAppConfig | PushoverConfig | WebhookConfig;
  created_at: string;
  updated_at: string;
}

export interface EmailConfig {
  smtp_server: string;
  smtp_port: number;
  username: string;
  password: string;
  from_email: string;
  recipients: string[];
}

export interface TelegramConfig {
  bot_token: string;
  chat_ids: string[];
}

export interface WhatsAppConfig {
  account_sid: string;
  auth_token: string;
  from_number: string;
  to_numbers: string[];
}

export interface PushoverConfig {
  api_token: string;
  user_key: string;
  priority: number;
}

export interface WebhookConfig {
  url: string;
  method: 'POST' | 'GET';
  headers: Record<string, string>;
}

// Debug configuration
const DEBUG = true;

const logError = (error: any, context: string) => {
  if (DEBUG) {
    console.group(`🔴 API Error: ${context}`);
    console.error('Error details:', error);
    console.error('Response:', error.response);
    console.error('Request config:', error.config);
    if (error.response) {
      console.error('Status:', error.response.status);
      console.error('Data:', error.response.data);
      console.error('Headers:', error.response.headers);
    }
    console.error('Stack:', error.stack);
    console.groupEnd();
  }
};

const api = axios.create({
  baseURL: API_BASE_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

// Add token to requests if available
api.interceptors.request.use((config: AxiosRequestConfig) => {
  const token = localStorage.getItem('token');
  if (token && config.headers) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

// Add request interceptor for debugging
api.interceptors.request.use(
  (config) => {
    if (DEBUG) {
      console.group(`🔵 API Request: ${config.method?.toUpperCase()} ${config.url}`);
      console.log('Request config:', config);
      console.groupEnd();
    }
    return config;
  },
  (error) => {
    logError(error, 'Request Interceptor');
    return Promise.reject(error);
  }
);

// Add response interceptor for debugging
api.interceptors.response.use(
  (response) => {
    if (DEBUG) {
      console.group(`🟢 API Response: ${response.config.method?.toUpperCase()} ${response.config.url}`);
      console.log('Response:', response);
      console.groupEnd();
    }
    return response;
  },
  (error) => {
    logError(error, 'Response Interceptor');
    return Promise.reject(error);
  }
);

export const authApi = {
  login: async (credentials: LoginCredentials) => {
    // Create URLSearchParams for x-www-form-urlencoded data
    const formData = new URLSearchParams();
    formData.append('username', credentials.username);
    formData.append('password', credentials.password);

    // Send as form data with proper content type
    const response = await api.post('/users/login', formData, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    });
    return response.data as Token;
  },

  register: async (data: RegisterData) => {
    const response = await api.post('/users/register', data);
    return response.data as User;
  },

  getCurrentUser: async () => {
    const response = await api.get('/users/me');
    return response.data as User;
  },

  getUsers: async (skip = 0, limit = 100) => {
    const response = await api.get('/users/', { params: { skip, limit } });
    return response.data as User[];
  },
};

export const cameraApi = {
  createCamera(data: CameraCreate) {
    return api.post<CameraResponse>('/cameras/', data);
  },

  getCameras(skip = 0, limit = 100) {
    return api.get<CameraResponse[]>(`/cameras/?skip=${skip}&limit=${limit}`);
  },

  getCamera(cameraId: number) {
    return api.get<CameraResponse>(`/cameras/${cameraId}`);
  },

  updateCamera(cameraId: number, data: CameraCreate) {
    return api.put<CameraResponse>(`/cameras/${cameraId}`, data);
  },

  createIssue(cameraId: number, data: CameraIssueCreate) {
    return api.post<CameraIssueResponse>(`/cameras/${cameraId}/issues`, data);
  },

  getIssues(cameraId: number) {
    return api.get<CameraIssueResponse[]>(`/cameras/${cameraId}/issues`);
  },

  getStream(cameraId: number) {
    return api.get<string>(`/cameras/${cameraId}/stream`);
  },

  takeSnapshot(cameraId: number) {
    return api.post<string>(`/cameras/${cameraId}/snapshot`);
  }
};

export const chatApi = {
  getMessages: async () => {
    const response = await api.get('/chat/messages');
    return response.data as ChatMessageResponse[];
  },

  sendMessage: async (data: ChatMessageCreate) => {
    const response = await api.post('/chat/messages', data);
    return response.data as ChatMessageResponse;
  },

  getConversation: async (userId: number) => {
    const response = await api.get(`/chat/messages/${userId}`);
    return response.data as ChatMessageResponse[];
  },

  markAsRead: async (messageId: number) => {
    const response = await api.put(`/chat/messages/${messageId}/read`);
    return response.data;
  },
};

export const casesApi = {
  getCases: async (skip = 0, limit = 100) => {
    const response = await api.get('/cases/cases', { params: { skip, limit } });
    return response.data;
  },

  getCase: async (caseId: number) => {
    const response = await api.get(`/cases/cases/${caseId}`);
    return response.data;
  },

  createCase: async (data: any) => {
    const response = await api.post('/cases/cases', data);
    return response.data;
  },

  validateCase: async (caseId: number) => {
    const response = await api.post(`/cases/cases/${caseId}/validate`);
    return response.data;
  },

  getValidationCriteria: async (skip = 0, limit = 100) => {
    const response = await api.get('/cases/validation-criteria', { params: { skip, limit } });
    return response.data;
  },

  createValidationCriteria: async (data: any) => {
    const response = await api.post('/cases/validation-criteria', data);
    return response.data;
  },

  toggleValidationCriteria: async (criteriaId: number) => {
    const response = await api.put(`/cases/validation-criteria/${criteriaId}/toggle`);
    return response.data;
  },
};

export const surveillanceRulesApi = {
  getRules() {
    return api.get<SurveillanceRule[]>('/surveillance-rules').then(response => response.data);
  },

  createRule(data: SurveillanceRuleCreate) {
    return api.post<SurveillanceRule>('/surveillance-rules', data).then(response => response.data);
  },

  updateRule(ruleId: number, data: Partial<SurveillanceRuleCreate>) {
    return api.put<SurveillanceRule>(`/surveillance-rules/${ruleId}`, data).then(response => response.data);
  },

  deleteRule(ruleId: number) {
    return api.delete(`/surveillance-rules/${ruleId}`);
  },

  getActiveRule() {
    return api.get<SurveillanceRule>('/surveillance-rules/active').then(response => response.data);
  },
};

export const agentApi = {
  getAgents: async () => {
    try {
      const response = await api.get<Agent[]>('/agents/');
      return response.data;
    } catch (error) {
      logError(error, 'getAgents');
      throw error;
    }
  },

  createAgent: async (data: AgentCreate) => {
    try {
      const response = await api.post<Agent>('/agents/', data);
      return response.data;
    } catch (error) {
      logError(error, 'createAgent');
      throw error;
    }
  },

  updateAgent: async (agentId: number, data: Partial<AgentCreate>) => {
    try {
      const response = await api.put<Agent>(`/agents/${agentId}/`, data);
      return response.data;
    } catch (error) {
      logError(error, 'updateAgent');
      throw error;
    }
  },

  deleteAgent: async (agentId: number) => {
    try {
      const response = await api.delete<void>(`/agents/${agentId}/`);
      return response.data;
    } catch (error) {
      logError(error, 'deleteAgent');
      throw error;
    }
  },
};

export const webhookApi = {
  getWebhooks: async () => {
    try {
      const response = await api.get<Webhook[]>('/webhooks/');
      return response.data;
    } catch (error) {
      logError(error, 'getWebhooks');
      throw error;
    }
  },

  createWebhook: async (data: WebhookCreate) => {
    try {
      const response = await api.post<Webhook>('/webhooks/', data);
      return response.data;
    } catch (error) {
      logError(error, 'createWebhook');
      throw error;
    }
  },

  updateWebhook: async (webhookId: number, data: WebhookUpdate) => {
    try {
      const response = await api.put<Webhook>(`/webhooks/${webhookId}/`, data);
      return response.data;
    } catch (error) {
      logError(error, 'updateWebhook');
      throw error;
    }
  },

  deleteWebhook: async (webhookId: number) => {
    try {
      const response = await api.delete<void>(`/webhooks/${webhookId}/`);
      return response.data;
    } catch (error) {
      logError(error, 'deleteWebhook');
      throw error;
    }
  },
};

export const integrationApi = {
  getIntegrations: async () => {
    try {
      const response = await api.get('/integrations');
      return response.data.integrations;
    } catch (error) {
      logError(error, 'getIntegrations');
      throw error;
    }
  },

  createIntegration: async (data: Omit<Integration, 'id' | 'created_at' | 'updated_at'>) => {
    try {
      const response = await api.post('/integrations', data);
      return response.data;
    } catch (error) {
      logError(error, 'createIntegration');
      throw error;
    }
  },

  updateIntegration: async (integrationId: string, data: Partial<Omit<Integration, 'id' | 'created_at' | 'updated_at'>>) => {
    try {
      const response = await api.patch(`/integrations/${integrationId}`, data);
      return response.data;
    } catch (error) {
      logError(error, 'updateIntegration');
      throw error;
    }
  },

  deleteIntegration: async (integrationId: string) => {
    try {
      await api.delete(`/integrations/${integrationId}`);
    } catch (error) {
      logError(error, 'deleteIntegration');
      throw error;
    }
  },
};

export const florenceApi = {
  /**
   * Fetch the structure of all available Florence APIs
   * @returns Promise resolving to the API structure
   */
  fetchApiStructure: async () => {
    const response = await api.get('/florence/api-structure');
    return response.data;
  },

  /**
   * Perform general image analysis
   * @param image Base64 encoded image
   * @param query Optional query string
   * @returns Promise resolving to analysis results
   */
  analyze: async (image: string, query?: string) => {
    const response = await api.post('/florence/analyze', { image, query });
    return response.data;
  },

  /**
   * Detect objects in an image
   * @param image Base64 encoded image
   * @returns Promise resolving to detected objects
   */
  detect: async (image: string) => {
    const response = await api.post('/florence/detect', { image });
    return response.data;
  },

  /**
   * Generate a caption for an image
   * @param image Base64 encoded image
   * @returns Promise resolving to image caption
   */
  caption: async (image: string) => {
    const response = await api.post('/florence/caption', { image });
    return response.data;
  },

  /**
   * Perform OCR on an image
   * @param image Base64 encoded image
   * @param x Optional x-coordinate for region-specific OCR
   * @param y Optional y-coordinate for region-specific OCR
   * @param width Optional width for region-specific OCR
   * @param height Optional height for region-specific OCR
   * @returns Promise resolving to OCR results
   */
  ocr: async (image: string, x?: number, y?: number, width?: number, height?: number) => {
    const response = await api.post('/florence/ocr', { image, x, y, width, height });
    return response.data;
  },

  /**
   * Segment an image based on a text expression
   * @param image Base64 encoded image
   * @param expression Text expression for segmentation
   * @returns Promise resolving to segmentation results
   */
  segment: async (image: string, expression: string) => {
    const response = await api.post('/florence/segment', { image, expression });
    return response.data;
  },

  /**
   * Perform custom open vocabulary detection
   * @param image Base64 encoded image
   * @param query Custom query for detection
   * @returns Promise resolving to detection results
   */
  detectCustom: async (image: string, query: string) => {
    const response = await api.post('/florence/detect-custom', { image, query });
    return response.data;
  }
};

export default api;
