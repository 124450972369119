export const trafficSurveillanceRules = [
  {
    title: 'Vehicle and Pedestrian Counting',
    items: [
      'Count total number of people and vehicles',
      'Check for parked vehicles and crosswalk users',
      'Identify unsafe pedestrians and gathering groups',
      'Assess traffic congestion and accidents',
      'Note any construction or road debris',
      'Check for traffic signals and their condition',
      'Count lane violations and illegal parking',
      'Monitor jaywalking and signal violations',
      'Check for unsafe distance between vehicles',
      'Identify potholes and damaged infrastructure',
      'Report any immediate safety risks or suspicious activities'
    ]
  }
];
