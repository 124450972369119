import React, { useCallback, useState } from 'react';
import {
  Box,
  Container,
  VStack,
  Heading,
  useToast,
  useColorModeValue,
  Button,
  HStack,
  Text,
  Grid,
  GridItem,
  Card,
  CardBody,
  Image,
  Divider,
  Badge,
  IconButton,
} from '@chakra-ui/react';
import { useDropzone } from 'react-dropzone';
import { DeleteIcon, AddIcon } from '@chakra-ui/icons';
import RulesInput from '../components/RulesInput';
import { cameraSurveillanceRules } from '../constants/cameraSurveillanceRules';
import { useAuth } from '../contexts/AuthContext';
import api from '../services/api';


interface Rule {
  id: string;
  name: string;
  description: string;
  enabled: boolean;
}

const SurveillanceDemo: React.FC = () => {
  const [selectedImage, setSelectedImage] = useState<File | null>(null);
  const [imagePreview, setImagePreview] = useState<string | null>(null);
  const [selectedRules, setSelectedRules] = useState<string[]>([]);
  const [isProcessing, setIsProcessing] = useState(false);
  const toast = useToast();
  const { user } = useAuth();

  const bg = useColorModeValue('white', 'gray.800');
  const borderColor = useColorModeValue('gray.200', 'gray.600');
  const dropzoneBg = useColorModeValue('gray.50', 'gray.700');

  // Transform rules into the format expected by RulesInput
  const transformedRules = React.useMemo(() => {
    const categories = Array.from(new Set(cameraSurveillanceRules.map(rule => rule.category)));
    return categories.map(category => ({
      title: category,
      items: cameraSurveillanceRules
        .filter(rule => rule.category === category)
        .map(rule => rule.description)
    }));
  }, []);

  const onDrop = useCallback((acceptedFiles: File[]) => {
    const file = acceptedFiles[0];
    if (file) {
      setSelectedImage(file);
      const preview = URL.createObjectURL(file);
      setImagePreview(preview);
    }
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: {
      'image/*': ['.jpeg', '.jpg', '.png']
    },
    maxFiles: 1
  });

  const handleProcessImage = async () => {
    if (!selectedImage) {
      toast({
        title: 'No image selected',
        description: 'Please select an image to process',
        status: 'warning',
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    try {
      setIsProcessing(true);
      const reader = new FileReader();
      const base64Image = await new Promise<string>((resolve, reject) => {
        reader.onload = () => resolve(reader.result as string);
        reader.onerror = reject;
        reader.readAsDataURL(selectedImage);
      });

      console.log('Image size:', base64Image.length);
      
      const requestData = {
        image: base64Image,
        user_id: user?.id?.toString() || '',
        username: user?.username || '',
        role: user?.role || '',
        rules: selectedRules,
        grayscale: false
      };

      console.log('Sending request to /depth/analyze');
      const response = await api.post('/depth/analyze', requestData);
      console.log('Received response:', response.data);
      
      toast({
        title: 'Success',
        description: 'Image processed successfully',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
      setImagePreview(response.data.depth_image);
    } catch (error) {
      toast({
        title: 'Error',
        description: 'Failed to process image',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setIsProcessing(false);
    }
  };

  const clearImage = () => {
    if (imagePreview) {
      URL.revokeObjectURL(imagePreview);
    }
    setSelectedImage(null);
    setImagePreview(null);
  };

  return (
    <Container maxW="container.xl" py={8}>
      <Grid templateColumns="repeat(12, 1fr)" gap={6}>
        {/* Left side - Image Upload */}
        <GridItem colSpan={{ base: 12, md: 8 }}>
          <Card>
            <CardBody>
              <VStack spacing={4} align="stretch">
                <Heading size="md">Surveillance Image Analysis</Heading>
                <Box
                  {...getRootProps()}
                  p={6}
                  bg={dropzoneBg}
                  borderRadius="lg"
                  borderWidth={2}
                  borderStyle="dashed"
                  borderColor={isDragActive ? 'blue.500' : borderColor}
                  cursor="pointer"
                  transition="all 0.2s"
                  _hover={{ borderColor: 'blue.500' }}
                >
                  <input {...getInputProps()} />
                  <VStack spacing={2}>
                    <AddIcon boxSize={6} color="gray.500" />
                    <Text textAlign="center">
                      {isDragActive
                        ? 'Drop the image here'
                        : 'Drag and drop an image here, or click to select'}
                    </Text>
                  </VStack>
                </Box>

                {imagePreview && (
                  <Box position="relative">
                    <Image
                      src={imagePreview}
                      alt="Preview"
                      borderRadius="lg"
                      maxH="400px"
                      w="100%"
                      objectFit="contain"
                    />
                    <IconButton
                      aria-label="Remove image"
                      icon={<DeleteIcon />}
                      position="absolute"
                      top={2}
                      right={2}
                      colorScheme="red"
                      onClick={clearImage}
                    />
                  </Box>
                )}

                <Button
                  colorScheme="blue"
                  isLoading={isProcessing}
                  onClick={handleProcessImage}
                  isDisabled={!selectedImage}
                >
                  Process Image
                </Button>
              </VStack>
            </CardBody>
          </Card>
        </GridItem>

        {/* Right side - Rules */}
        <GridItem colSpan={{ base: 12, md: 4 }}>
          <Card>
            <CardBody>
              <VStack spacing={4} align="stretch">
                <Heading size="md">Surveillance Rules</Heading>
                <RulesInput
                  rules={transformedRules}
                  selectedRules={selectedRules}
                  onChange={setSelectedRules}
                />
                <Divider />
                <Box>
                  <Text fontWeight="bold" mb={2}>Selected Rules:</Text>
                  <VStack align="stretch" spacing={2}>
                    {selectedRules.map((rule, index) => (
                      <Badge key={index} colorScheme="blue" p={2} borderRadius="md">
                        {rule}
                      </Badge>
                    ))}
                  </VStack>
                </Box>
              </VStack>
            </CardBody>
          </Card>
        </GridItem>
      </Grid>
    </Container>
  );
};

export default SurveillanceDemo;
