import React from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  ModalFooter,
  Button,
  FormControl,
  FormLabel,
  Input,
  VStack,
  useToast,
} from '@chakra-ui/react';
import { Site } from '../../types/site';
import { siteService } from '../../services/siteService';

interface SiteModalProps {
  isOpen: boolean;
  onClose: () => void;
  site: Site | null;
  onSave: () => void;
}

const SiteModal: React.FC<SiteModalProps> = ({ isOpen, onClose, site, onSave }) => {
  const toast = useToast();
  const [formData, setFormData] = React.useState<Partial<Site>>({
    location_name: '',
    address: '',
    latitude: 0,
    longitude: 0,
    admin_name: '',
    admin_contact_number: '',
  });

  React.useEffect(() => {
    if (site) {
      setFormData({
        location_name: site.location_name,
        address: site.address,
        latitude: site.latitude || 0,
        longitude: site.longitude || 0,
        admin_name: site.admin_name || '',
        admin_contact_number: site.admin_contact_number || '',
      });
    } else {
      setFormData({
        location_name: '',
        address: '',
        latitude: 0,
        longitude: 0,
        admin_name: '',
        admin_contact_number: '',
      });
    }
  }, [site]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: name === 'latitude' || name === 'longitude' ? parseFloat(value) || 0 : value,
    }));
  };

  const handleSubmit = async () => {
    try {
      if (!formData.location_name || !formData.address) {
        toast({
          title: 'Error',
          description: 'Location name and address are required',
          status: 'error',
          duration: 3000,
        });
        return;
      }

      if (site) {
        await siteService.updateSite(site.site_id, formData);
        toast({
          title: 'Site updated',
          status: 'success',
          duration: 3000,
        });
      } else {
        await siteService.createSite(
          formData as Omit<Site, 'site_id' | 'created_at' | 'updated_at' | 'channels'>
        );
        toast({
          title: 'Site created',
          status: 'success',
          duration: 3000,
        });
      }
      onSave();
    } catch (error) {
      toast({
        title: 'Error',
        description: 'Failed to save site',
        status: 'error',
        duration: 3000,
      });
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="lg">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{site ? 'Edit Site' : 'Add Site'}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <VStack spacing={4}>
            <FormControl isRequired>
              <FormLabel>Location Name</FormLabel>
              <Input
                name="location_name"
                value={formData.location_name}
                onChange={handleChange}
              />
            </FormControl>
            <FormControl isRequired>
              <FormLabel>Address</FormLabel>
              <Input
                name="address"
                value={formData.address}
                onChange={handleChange}
              />
            </FormControl>
            <FormControl>
              <FormLabel>Latitude</FormLabel>
              <Input
                name="latitude"
                type="number"
                value={formData.latitude}
                onChange={handleChange}
              />
            </FormControl>
            <FormControl>
              <FormLabel>Longitude</FormLabel>
              <Input
                name="longitude"
                type="number"
                value={formData.longitude}
                onChange={handleChange}
              />
            </FormControl>
            <FormControl>
              <FormLabel>Admin Name</FormLabel>
              <Input
                name="admin_name"
                value={formData.admin_name}
                onChange={handleChange}
              />
            </FormControl>
            <FormControl>
              <FormLabel>Admin Contact Number</FormLabel>
              <Input
                name="admin_contact_number"
                value={formData.admin_contact_number}
                onChange={handleChange}
              />
            </FormControl>
          </VStack>
        </ModalBody>
        <ModalFooter>
          <Button variant="ghost" mr={3} onClick={onClose}>
            Cancel
          </Button>
          <Button colorScheme="blue" onClick={handleSubmit}>
            Save
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default SiteModal;
