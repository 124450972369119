export interface CameraSurveillanceRule {
  id: string;
  description: string;
  category: string;
}

export const cameraSurveillanceRules: CameraSurveillanceRule[] = [
  {
    id: 'camera-1',
    description: 'Specify how many people you see as people count',
    category: 'Monitoring',
  },
  {
    id: 'camera-2',
    description: 'Count people excluding shadows on glass door or people in posters',
    category: 'Monitoring',
  },
  {
    id: 'camera-3',
    description: 'Count people sitting or lying or sleeping on the floor',
    category: 'Monitoring',
  },
  {
    id: 'camera-4',
    description: 'Count people wearing helmet, balaclava, or hood',
    category: 'Monitoring',
  },
  {
    id: 'camera-5',
    description: 'Check any vehicle in the view',
    category: 'Monitoring',
  },
  {
    id: 'camera-6',
    description: 'Check if anyone is holding a weapon, knife, or rod-like object',
    category: 'Security',
  },
  {
    id: 'camera-7',
    description: 'Assess if there is threat to human life or property',
    category: 'Security',
  },
  {
    id: 'camera-8',
    description: 'Check for any unattended bags',
    category: 'Security',
  },
  {
    id: 'camera-9',
    description: 'Count the ATM machines in view',
    category: 'Monitoring',
  },
  {
    id: 'camera-10',
    description: 'Try to read the time of the event printed on corners',
    category: 'Monitoring',
  },
];

export default cameraSurveillanceRules;
