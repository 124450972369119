import { Message } from '../types/chat';

const DB_NAME = 'chatDB';
const DB_VERSION = 1;
const CHATS_STORE = 'chats';

interface ChatRecord {
  botType: string;
  messages: Message[];
  timestamp: number;
}

class DatabaseService {
  private db: IDBDatabase | null = null;

  async initDB(): Promise<void> {
    return new Promise((resolve, reject) => {
      const request = indexedDB.open(DB_NAME, DB_VERSION);

      request.onerror = () => {
        console.error("Error opening database");
        reject(request.error);
      };

      request.onsuccess = () => {
        this.db = request.result;
        resolve();
      };

      request.onupgradeneeded = (event) => {
        const db = (event.target as IDBOpenDBRequest).result;
        if (!db.objectStoreNames.contains(CHATS_STORE)) {
          const store = db.createObjectStore(CHATS_STORE, { keyPath: 'botType' });
          store.createIndex('timestamp', 'timestamp', { unique: false });
        }
      };
    });
  }

  async saveChat(botType: string, messages: Message[]): Promise<void> {
    if (!this.db) {
      await this.initDB();
    }

    return new Promise((resolve, reject) => {
      const transaction = this.db!.transaction([CHATS_STORE], 'readwrite');
      const store = transaction.objectStore(CHATS_STORE);

      const chatRecord: ChatRecord = {
        botType,
        messages,
        timestamp: Date.now(),
      };

      const request = store.put(chatRecord);

      request.onerror = () => {
        console.error("Error saving chat");
        reject(request.error);
      };

      request.onsuccess = () => {
        resolve();
      };
    });
  }

  async getChat(botType: string): Promise<Message[]> {
    if (!this.db) {
      await this.initDB();
    }

    return new Promise((resolve, reject) => {
      const transaction = this.db!.transaction([CHATS_STORE], 'readonly');
      const store = transaction.objectStore(CHATS_STORE);
      const request = store.get(botType);

      request.onerror = () => {
        console.error("Error getting chat");
        reject(request.error);
      };

      request.onsuccess = () => {
        const record = request.result as ChatRecord;
        resolve(record?.messages || []);
      };
    });
  }

  async clearChat(botType: string): Promise<void> {
    if (!this.db) {
      await this.initDB();
    }

    return new Promise((resolve, reject) => {
      const transaction = this.db!.transaction([CHATS_STORE], 'readwrite');
      const store = transaction.objectStore(CHATS_STORE);
      const request = store.delete(botType);

      request.onerror = () => {
        console.error("Error clearing chat");
        reject(request.error);
      };

      request.onsuccess = () => {
        resolve();
      };
    });
  }
}

// Export a singleton instance
export const databaseService = new DatabaseService();
