import React, { useEffect, useState } from 'react';
import {
  Box,
  SimpleGrid,
  Stat,
  StatLabel,
  StatNumber,
  StatHelpText,
  StatArrow,
  Text,
  Heading,
  useColorModeValue,
  Spinner,
  Center,
} from '@chakra-ui/react';

interface StatsCardProps {
  title: string;
  stat: string;
  status?: 'increase' | 'decrease';
  statusValue?: string;
  description?: string;
}

function StatsCard(props: StatsCardProps) {
  const { title, stat, status, statusValue, description } = props;
  const bgColor = useColorModeValue('white', 'gray.700');

  return (
    <Stat
      px={{ base: 4, md: 8 }}
      py="5"
      bg={bgColor}
      shadow="base"
      rounded="lg"
    >
      <StatLabel fontWeight="medium" isTruncated>
        {title}
      </StatLabel>
      <StatNumber fontSize="2xl" fontWeight="medium">
        {stat}
      </StatNumber>
      {status && statusValue && (
        <StatHelpText>
          <StatArrow type={status} />
          {statusValue}
        </StatHelpText>
      )}
      {description && (
        <Text fontSize="sm" color="gray.500">
          {description}
        </Text>
      )}
    </Stat>
  );
}

interface DashboardData {
  active_atms: StatData;
  incidents_today: StatData;
  response_time: StatData;
  total_cases: StatData;
  maintenance_atms: StatData;
  system_health: StatData;
}

interface StatData {
  current: string | number;
  previous?: string | number;
  change?: 'increase' | 'decrease';
  change_value?: string;
  description?: string;
}

export default function Dashboard() {
  const [dashboardData, setDashboardData] = useState<DashboardData | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchDashboardData = async () => {
      try {
        const response = await fetch('/api/dashboard/stats');
        if (!response.ok) {
          throw new Error('Failed to fetch dashboard data');
        }
        const data = await response.json();
        setDashboardData(data);
      } catch (err) {
        setError(err instanceof Error ? err.message : 'An error occurred');
      } finally {
        setIsLoading(false);
      }
    };

    fetchDashboardData();
  }, []);

  if (isLoading) {
    return (
      <Center h="50vh">
        <Spinner size="xl" />
      </Center>
    );
  }

  if (error) {
    return (
      <Center h="50vh">
        <Text color="red.500">Error: {error}</Text>
      </Center>
    );
  }

  return (
    <Box maxW="7xl" mx="auto" pt={5} px={{ base: 2, sm: 12, md: 17 }}>
      <Heading mb={6}>Dashboard</Heading>
      
      <SimpleGrid columns={{ base: 1, md: 2, lg: 3 }} spacing={{ base: 5, lg: 8 }}>
        {dashboardData && (
          <>
            <StatsCard
              title="Active ATMs"
              stat={dashboardData.active_atms.current.toString()}
              status={dashboardData.active_atms.change}
              statusValue={dashboardData.active_atms.change_value}
            />
            <StatsCard
              title="Incidents Today"
              stat={dashboardData.incidents_today.current.toString()}
              status={dashboardData.incidents_today.change}
              statusValue={dashboardData.incidents_today.change_value}
            />
            <StatsCard
              title="Average Response Time"
              stat={dashboardData.response_time.current.toString()}
              description={dashboardData.response_time.description}
            />
            <StatsCard
              title="Total Cases"
              stat={dashboardData.total_cases.current.toString()}
              status={dashboardData.total_cases.change}
              statusValue={dashboardData.total_cases.change_value}
            />
            <StatsCard
              title="ATMs in Maintenance"
              stat={dashboardData.maintenance_atms.current.toString()}
              description={dashboardData.maintenance_atms.description}
            />
            <StatsCard
              title="System Health"
              stat={dashboardData.system_health.current.toString()}
              status={dashboardData.system_health.change}
              statusValue={dashboardData.system_health.change_value}
            />
          </>
        )}
      </SimpleGrid>
    </Box>
  );
}
